import { fetchRS } from "../utils/fetchRS";

export const getBanco = (props:any) => {
    return fetchRS(`${process.env.REACT_APP_API_URL}/catalogos/banco`,undefined,props);
}
export const createBanco = (props:any) => {
    return fetchRS(`${process.env.REACT_APP_API_URL}/catalogos/banco`, {
        method: 'PUT',
        objectBody: props
    });
}