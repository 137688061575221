export const SUPERADMIN = 3
export const ADMIN = 2;
export const VISOR = 2;

export const ROLES = {
    ADMIN,
    VISOR,
    SUPERADMIN
}
/**
 * 
 */
export const SCOPES_BY_ROLES = {

    /**
     * SUPERADMIN
     */
    SCP_0:[SUPERADMIN],

    /**
     * SUPERADMIN, ADMIN
     */
    SCP_1: [SUPERADMIN, ADMIN],

    /**
     * SUPERADMIN, ADMIN, VISOR
     */
    SCP_2: [SUPERADMIN, ADMIN, VISOR],

}