let message: { [key: string]: string } = {
    cell: 'El campo debe ser un telefono celular (6000-0000)',
    phone: 'El campo debe ser un telefono recidencial (123-4567)',
    cellorphone: 'El campo debe ser un telefono celular o recidencial valido',
    cedula: 'El campo debe ser una cédula (8-88-8888)',
    required: 'El campo es requerido',
    email: 'El campo debe ser un correo (email@correo.com)',
    min: 'El campo debe ser mayor o igual a {?}',
    max: 'El campo debe ser menor o igual a {?}',
    minlength: `El campo debe tener una longitud mayor a {?}`,
    maxlength: `El campo debe tener una longitud menor a {?}`,
}
export type TargetProps = (EventTarget & HTMLInputElement) | (EventTarget & HTMLSelectElement) | (EventTarget & HTMLTextAreaElement);

export const setMessage = (newMessage: { [key: string]: string }) => {
    message = { ...message, ...newMessage }
}
const validations = {
    correct: (regex: RegExp, target: TargetProps) => {
        if (!regex.test(target.value) && target.value.length > 0) {
            target.value = target.value.substring(0, target.value.length - 1);
            validations.correct(regex, target);
        }
    },
    custom: (target: TargetProps, startWhen: string) => {
        if (startWhen === "change" && target.value) {
            if (target.dataset.letter && !(/^[A-Z\u00f1 ]+$/i.test(target.value))) {
                validations.correct(/^[A-Z\u00f1 ]+$/i, target);
            }
            if (target.dataset.integer && !(/^[1-9]+$/.test(target.value))) {
                validations.correct(/^[1-9]+$/, target);
            }
            if (target.dataset.double && !(/^[\d]+[.]?([\d]+)?$/.test(target.value))) {
                validations.correct(/^[\d]+[.]?([\d]+)?$/, target);
            }
            if (target.dataset.alphanumeric && !(/^[0-9a-zA-Z]+$/.test(target.value))) {
                validations.correct(/^[0-9a-zA-Z]+$/, target);
            }
        }
        if (target.dataset.type === 'cell' && target.value) {
            startWhen === "change" && validations.correct(/^((6[\d]{0,7})|(6[\d]{3}-[\d]{0,4}))$/, target);
            if (/^6[\d]{3}-[\d]{4}$/.test(target.value) || /^6[\d]{7}$/.test(target.value) || !target.value) {
                target.value = target.value.replace(/^(6[\d]{3})([\d]{4})$/, "$1-$2");
            } else {
                return { valid: false, msj: message.cell };
            }
        }
        if (target.dataset.type === 'phone' && target.value) {
            startWhen === "change" && validations.correct(/^(([1-9][\d]{0,6})|([1-9][\d]{2}-[\d]{0,4}))$/, target);
            if (/^[1-9][\d]{2}-[\d]{4}$/.test(target.value) || /^[1-9][\d]{6}$/.test(target.value) || !target.value) {
                target.value = target.value.replace(/^([1-9][\d]{2})([\d]{4})$/, "$1-$2");
            } else {
                return { valid: false, msj: message.phone };
            }
        }
        if (target.dataset.type === 'cellorphone' && target.value) {
            startWhen === "change" && validations.correct(/^(([1-9][\d]{0,6})|([1-9][\d]{2}-[\d]{0,4})|(6[\d]{0,7})|(6[\d]{3}-[\d]{0,4})|(6[\d]{2}-[\d]{0,5}))$/, target);
            const val = target.value.replace('-', '');
            if (/^6[\d]{7}$/.test(val) || /^6[\d]{2}-[\d]{0,5}$/.test(val) || !target.value) {
                target.value = val.replace(/^(6[\d]{3})([\d]{4})$/, "$1-$2");
            } else if (/^[1-9][\d]{6}$/.test(val) || /^6[\d]{3}-[\d]{3}$/.test(val)) {
                target.value = val.replace(/^([1-9][\d]{2})([\d]{4})$/, "$1-$2");
            } else {
                return { valid: false, msj: message.cellorphone };
            }
        }
        if (target.dataset.type === 'cedula' && target.value) {
            target.value = target.value.toUpperCase();
            if (!(/^([1-9]|1[0-3]|N|E|PE|[1-9]PI|[1-9]AV)[-][1-9]([\d]{1,3})?[-][1-9]([\d]{1,5})?$/.test(target.value))) {
                return { valid: false, msj: message.cedula };
            }
        }
        return null;
    },
    native: (target: TargetProps) => {
        if (!target.disabled) {
            const check = (target as HTMLInputElement);
            if (target.required && check && (!check.checked && !target.value)) {
                return { valid: false, msj: message.required };
            }
            if (target.value) {
                if (target.dataset.type === 'email' && !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})$/.test(target.value))) {
                    return { valid: false, msj: message.email };
                }
                if (target.dataset.min) {
                    if (Number(target.dataset.min) > Number(target.value || 0)) {
                        return { valid: false, msj: message.min.replace('{?}', target.dataset.min) };
                    } else if (["datetime", "datetime-local", "time", "date", "month", "week"].indexOf(target.type) >= 0
                        && new Date(target.dataset.min) > new Date(target.value)) {
                        return { valid: false, msj: message.min.replace('{?}', target.dataset.min) };
                    }
                }
                if (target.dataset.max) {
                    if (Number(target.value || 0) > Number(target.dataset.max)) {
                        return { valid: false, msj: message.max.replace('{?}', target.dataset.max) };
                    } else if (["datetime", "datetime-local", "time", "date", "month", "week"].indexOf(target.type) >= 0
                        && new Date(target.value) > new Date(target.dataset.max)) {
                        return { valid: false, msj: message.max.replace('{?}', target.dataset.max) };
                    }
                }
                if (target.dataset.minlength && Number(target.dataset.maxlength) > Number(target.value.length)) {
                    return { valid: false, msj: message.minlength.replace('{?}', target.dataset.minlength) };
                }
                if (target.dataset.maxlength && Number(target.value.length) > Number(target.dataset.maxlength)) {
                    return { valid: false, msj: message.maxlength.replace('{?}', target.dataset.maxlength) };
                }
            }
        }
        return null;
    },
    actions: (target: TargetProps, startWhen: string) => {
        if (target.dataset.uppercase && target.dataset.uppercase === startWhen) {
            target.value = target.value.toUpperCase();
        }
        if (target.dataset.lowercase && target.dataset.lowercase === startWhen) {
            target.value = target.value.toLowerCase();
        }
        if (target.dataset.capitalize && target.dataset.capitalize === startWhen) {
            target.value = target.value.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
        }
        if (target.dataset.trim && target.dataset.trim === startWhen) {
            target.value = target.value.trim()
        }
        return null;
    },
    init: (target: TargetProps, startWhen: string) => {
        return validations.actions(target, startWhen) || validations.native(target) || validations.custom(target, startWhen) || { valid: true };
    }
}
const initStyles = () => {
    const style = document.createElement('style');
    style.innerHTML = `
    .spn-validate{
        display: block;
        width: 100%;
        margin-top: .25rem;
        font-size: .875em;
        color: #dc3545!important;
    }
    .inp-invalid{
        border: 1px solid #dc3545!important;
        
    }
    .inp-invalid:focus{
        border-color: #dc3545!important;
    }
    `;
    document.getElementsByTagName('head')[0].appendChild(style);
}
initStyles();


/**
 * valida los input al cambiar el valor
 * @param {*} target 
 * 
 */
export const inputValidate = (target: TargetProps) => {
    if (typeof target === 'object' && Object.keys(target).length > 0 && target.parentNode) {
        const span = target.nextElementSibling;
        if (span?.classList?.contains("spn-validate")) {
            span.remove();
            target.classList.remove("inp-invalid");
        }
        const valid = validations.init(target, "change");
        if (valid && !valid.valid) {
            const msj = document.createElement("span");
            msj.className = "spn-validate"
            msj.innerHTML = valid.msj;
            target.parentNode?.insertBefore(msj, target.nextSibling);
            target.classList.add("inp-invalid");
        }
        return valid?.valid;
    }
    return null;
}
/**
 * valida el formulario
 */
export const formValidate = (frm: HTMLFormControlsCollection) => {

    let isValid = true;
    if (frm) {
        Array.from(frm).forEach((control) => {
            const _elementControl = (control as TargetProps);
            if (_elementControl.required || _elementControl.value) {
                const span = _elementControl.nextElementSibling;
                if (span?.classList?.contains("spn-validate")) {
                    span.remove();
                    _elementControl.classList.remove("inp-invalid");
                }
                const valid = validations.init(_elementControl, "submit");
                if (valid && !valid.valid) {
                    const msj = document.createElement("span");
                    msj.className = "spn-validate"
                    msj.innerHTML = valid.msj;
                    _elementControl.parentNode?.insertBefore(msj, _elementControl.nextSibling);
                    _elementControl.classList.add("inp-invalid");
                    _elementControl.focus();
                    isValid = false;
                }
            }
        });

    }

    return isValid;
}