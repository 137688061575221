import authReducer from "../reducers/authReducer";
import msgReducer from "../reducers/msgReducer";
import spinerReducer from "../reducers/spinerReducer";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const reducers = combineReducers({
    auth: authReducer,
    msg: msgReducer,
    spiner: spinerReducer
})

export const store = configureStore({
    reducer: reducers
    
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>


export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector