import { fetchRS } from "../utils/fetchRS";

export const reporteMida = (body: { [key: string]: any }, type = 'json') => {
  return fetchRS(`${process.env.REACT_APP_API_URL}/reportes/subsidio-mida/${type}`, {
    method: 'POST',
    objectBody: body
  });
}
export const litrosPorMeses = (body: { [key: string]: any }, type = 'json') => {
  return fetchRS(`${process.env.REACT_APP_API_URL}/reportes/litros-por-meses/${type}`, {
    method: 'POST',
    objectBody: body
  });
}

export const payDriversByDates = (body: { [key: string]: any }, type = 'json') => {
  return fetchRS(`${process.env.REACT_APP_API_URL}/reportes/conductores-por-fechas/${type}`, {
    method: 'POST',
    objectBody: body
  });
}


export const getHomeInfo = () => {
  return fetchRS(`${process.env.REACT_APP_API_URL}/reportes/home-info`);
}


