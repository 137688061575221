export const initialBanco = {
  id: '',
  nombre: '',
  /* codigo: '', */
  estado: 1,
}
export type propsBanco = typeof initialBanco;

export const initialRutas = {
  id: 0,
  id_planta: 0,
  codigo: '',
  nombre: '',
  descripcion: '',
  estado: 1
}
export type propsRutas = typeof initialRutas;

export const initialCupo = {
  id: 0,
  id_planta: 0,
  id_ruta: 0,
  codigo: '',
  estado: 1
}
export type propsCupo = typeof initialCupo;

export const initialConductor = {
  id: 0,
  id_planta: 0,
  id_ruta: 0,
  tipo_pago: 'PL',
  pago_litros: 0,
  pago_fijo: 0,
  forma_pago: '',
  id_banco: 0,
  nombre_cuenta: '',
  tipo_cuenta: '',
  cedula: '',
  primer_nombre: '',
  segundo_nombre: '',
  primer_apellido: '',
  segundo_apellido: '',
  estado: true,
  dv:''
}
export type propsConductor = typeof initialConductor;

export const initialProveedores = {
  id: 0,
  id_planta: 0,
  id_ruta: 0,
  id_cupo: 0,
  cedula: '',
  primer_nombre: '',
  segundo_nombre: '',
  primer_apellido: '',
  segundo_apellido: '',
  precio_litro: 0,
  forma_pago: '',
  nombre_cuenta: '',
  tipo_cuenta: '',
  id_banco: 0,
  estado: 1,
  dv:'',
}
export type propsProveedores = typeof initialProveedores;

export const initialPlantas = {
  id: 0,
  nombre: '',
  estado: true
}
export type propsPlantas = typeof initialPlantas;

export const initialPeso = {
  id: 0,
  id_usuario: 0,
  id_proveedor: 0,
  id_conductor: 0,
  id_planta: '',
  id_cupo: '',
  fecha: '',
  peso_entrada_lts: 0.0,
  peso_salida_lts: 0.0,
  tipo_estado: 0,
  cantidad: '',
  comentario: '',
}

export type propsPeso = typeof initialPeso;


export interface tablePaginationRs {
  rows: Array<{ [key: string]: any }>;
  count: number
}