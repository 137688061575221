export const setCookie = (name: string, value: string) => {
  document.cookie += `${name}=${value};`
}
export const checkCookie = (name: string) => document.cookie.includes(`${name}=`);
export const getCookie = (name: string) => document.cookie.match(new RegExp(`${name}=([^;]+)`))?.[1] || '';


export const replaceCookies = (obj: { [key: string]: string }) => {
  document.cookie = Object.entries(obj).map(ob => ob.join('=')).join(';')
}

export const getColorByStatusPeso = (status: string) => ({
  '10': 'text-warning',
  '15': 'text-success',
  '0': 'text-primary'
})[status]