import { Navigate } from "react-router-dom";

interface PropsPrincipal {
    needScope: number[];
    userRoles?: number;
    component: any;
}

type Props = PropsPrincipal & Partial<{ [key: string]: any }>;

export const RolRouter = ({ needScope, userRoles, component: Component, ...rest }: Props) => {
    const isAllowed = userRoles && needScope.includes(userRoles);
    return (
        <>
            {
                isAllowed
                    ? <Component {...rest} />
                    : <Navigate to="/" />
            }
        </>
    )
}
