import React, { useContext } from 'react';
import { TableContext } from "./Table"
import { TitleTableProps } from "../interfaces/interfaces"
import { useTbody } from '../hooks/useTbody';

interface props {
    children?: React.ReactElement | React.ReactElement[];
    className?: string;
    style?: React.CSSProperties;
    titles?: Array<TitleTableProps>;
}

export const TBody = ({ children, style, className }: props) => {
    const { dataTable, page, pageAction } = useContext(TableContext);;
    const bodyShow = useTbody({ ...dataTable, page, ...pageAction })
    return (
        <tbody className={className} style={style}>
            {
                children
                    ? children
                    : <>{
                        bodyShow?.map((tr, i) => <tr key={'tr_' + i}>
                            {tr.map((td, j) => <td key={'td_' + i + '_' + j}>{td}</td>)}
                        </tr>)
                    }</>
            }
        </tbody>
    )
}