import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDoneFetchActions } from "../../hooks/useDoneFetchActions";
import useForm from "../../hooks/useForm";
import { initialRutas, propsRutas, initialPlantas } from '../../utils/formsInitialsStates';
import { getPlantas } from '../../services/adminServices';

interface Props {
  handleSubmit?: (values: propsRutas) => void;
  data?: propsRutas,
  on?: string
}

export const RutaForm = forwardRef(({ handleSubmit, data, on }: Props, ref) => {
  const doneFetchActions = useDoneFetchActions();
  const { handleInputChange, formValues, resetForm, changeMulti, validateForm } = useForm<propsRutas>(initialRutas);
  const form = useRef<HTMLFormElement>(null);
  const [plantas, setPlantas] = useState([initialPlantas])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit && handleSubmit(formValues);
  }
  useImperativeHandle(ref, () => ({
    handleClick: () => {
      form.current?.requestSubmit();
      if (form.current && validateForm(form.current.elements)) {
        return formValues;
      }
    },
    resetForm: () => resetForm()
  }));
  useEffect(() => {
    doneFetchActions(getPlantas()).then(resp => {
      setPlantas(resp)
    })
  }, [doneFetchActions])

  useEffect(() => {
    if (data) changeMulti(data);
  }, [data, changeMulti])
  return (
    <form className="p-3" id='formBancos' ref={form} autoComplete="off" onSubmit={submitForm}>
      <input type="hidden" name="id" value={formValues.id} onChange={handleInputChange} />
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="nombre" name="nombre" value={formValues.nombre} onChange={handleInputChange}
              placeholder="-" data-type='alphanumeric' data-min={3} data-capitalize="change" data-trim="submit" required />
            <label htmlFor="nombre">Nombre</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="codigo" name="codigo" value={formValues.codigo} onChange={handleInputChange}
              placeholder="-" data-type='alphanumeric' data-uppercase="change" data-trim="submit" required />
            <label htmlFor="codigo">Código</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="estado" name="estado" value={+formValues.estado} onChange={handleInputChange} required>
              <option value="" disabled>Seleccione un estado</option>
              <option value={1}>Activo</option>
              <option value={0}>Inactivo</option>
            </select>
            <label htmlFor="estado">Estado</label>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_planta" name="id_planta" value={formValues.id_planta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Planta</option>
              {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_planta">Planta</label>
          </div>
        </div>

        {/* <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="tipo_pago" name="tipo_pago" value={formValues.tipo_pago} onChange={handleInputChange} required>
              <option value="" disabled>Seleccione un tipo de pago</option>
              <option value="FIJO">Fijo</option>
              <option value="POR_LITROS">Por Litros</option>
            </select>
            <label htmlFor="tipo_pago">Tipo de pago</label>
          </div>
        </div> */}
      </div>
      <div className="row">
        {/* {formValues.tipo_pago === 'POR_LITROS' && <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="precio_x_medida" name="precio_x_medida" value={formValues.precio_x_medida} onChange={handleInputChange}
              placeholder="-" data-type='number' data-min={0} required />
            <label htmlFor="precio_x_medida">Precio por medida</label>
          </div>
        </div>}

        {formValues.tipo_pago === 'FIJO' && <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="pago_fijo" name="pago_fijo" value={formValues.pago_fijo} onChange={handleInputChange}
              placeholder="-" data-type='number' data-min={0} required />
            <label htmlFor="pago_fijo">Pago fijo</label>
          </div>
        </div>} */}

      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <textarea className="form-control" id="descripcion" name="descripcion" value={formValues.descripcion} onChange={handleInputChange}
              placeholder="-" data-type='string' data-min={10} data-max={200} data-trim="submit" />
            <label htmlFor="descripcion">Descripción</label>
          </div>
        </div>
      </div>
    </form>

  )
})
