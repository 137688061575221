import { useState } from "react";
import { login } from "../../reducers/authReducer";

import './login.css';
import {  useAppDispatch } from "../../store/store";

export const Login = () => {
    const dispatch = useAppDispatch();
    const [form, setForm] = useState({ username: '', password: '' })
    const { username, password } = form;

    const formInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(login(form));
    }
    return (
        <div className="login-container">
            <div className="card login-card">
                <div className="row no-gutters">
                    <div className="col-md-5">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/login.jpg`} alt="login" className="login-card-img" />
                    </div>
                    <div className="col-md-7 d-grid justify-content-center">
                        <div className="card-body m-auto">
                            <div className="brand-wrapper text-center">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/uvilorBlack.png`} alt="logo" className="logo" />
                            </div>
                            <p className="login-card-description">Inicio de sesión</p>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="sr-only">usuario</label>
                                    <input type="text" name="username" id="username" className="form-control" placeholder="username" value={username} onChange={formInputChange} autoFocus />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="sr-only">contraseña</label>
                                    <input type="password" name="password" className="form-control" placeholder="***********" value={password} onChange={formInputChange} />
                                </div>
                                <div className="text-center">
                                    <input name="login" id="login" className="btn btn-block login-btn my-4" type="submit" value="Iniciar Sesión" />
                                </div>
                            </form>
                            {/* <p className="login-card-footer-text"><a href="#!" className="text-reset">¿Olvido su Contraseña? Reciba ayuda aquí</a></p>
                            <nav className="login-card-footer-nav text-center">
                                <a href="#!">Terminos de uso y política de privacidad</a>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
