import { Table } from "../components/table-pagination-sorting/components/Table"
import { useEffect, useRef, useState } from 'react';
import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { getPesoById, getPesos, savetPeso } from "../services/pesosServices";
import { propsPeso, tablePaginationRs } from "../utils/formsInitialsStates";
import { Modals } from "../components/Modals";
import { PesoForm } from "../components/forms-modals/PesoForm";
import { useAppDispatch } from "../store/store";
import { showMessage } from "../reducers/msgReducer";
import { getColorByStatusPeso } from "../utils/utility";
import { PlantaFechaFilterProps, PlantaFechas } from "../components/others/Filters/PlantaFechas";

export const Pesos = () => {

  const doneFetchActions = useDoneFetchActions();
  const dispatch = useAppDispatch();
  const [datosPeso, setDatosPeso] = useState({} as { on: string, data: propsPeso | undefined })
  const pesoFormRef = useRef<{ handleClick: () => propsPeso; resetForm: () => void; }>();
  const [data, setData] = useState({ count: 0 } as { count: number; rows: Array<{ [key: string]: any }>; info?: any });

  const [filterData, setDataFilter] = useState({ fechaIni: new Intl.DateTimeFormat("fr-CA").format(Date.now()) } as filtersProps);
  interface filtersProps extends PaginatingAndSortingProps, PlantaFechaFilterProps { }

  useEffect(() => {
    function searchDataPeso(tipe: string, id: number) {
      doneFetchActions(getPesoById(id)).then((resp: propsPeso) => {
        setDatosPeso({ on: tipe, data: resp });
      })
    }
    doneFetchActions(getPesos(filterData)).then((data: tablePaginationRs) => {
      data.rows = data.rows?.map(ob => ({
        ...ob,
        actions: <>
          <button
            className={`btn ${getColorByStatusPeso(ob.tipo_estado)}`}
            onClick={() => searchDataPeso("edit", ob.id)}
          ><span className="icon-create"></span></button>
        </>
      }))
      setData(data)
    })
  }, [filterData, doneFetchActions])


  const onSaveModal = () => {
    const pesoValues = pesoFormRef.current?.handleClick();
    if (pesoValues) {
      doneFetchActions(savetPeso(pesoValues)).then(resp => {
        dispatch(showMessage({
          isShow: true,
          title: resp.message || "Litro Guardado Satisfactoriamente",
          type: 'success'
        }))
        setDataFilter(ob => ob);
        pesoFormRef.current?.resetForm();
        if (datosPeso.on === "edit") {
          setDatosPeso({ on: '', data: undefined })
        }
      })
    }
  }
  const onCloseModal = () => {
    pesoFormRef.current?.resetForm();
    setDatosPeso({ on: '', data: undefined })
  }
  const handleSubmit = (dataFilter: PlantaFechaFilterProps) => {
    setDataFilter((dt) => ({ ...dt, ...dataFilter }));
  }
  const handleChangeTable = (pageAction: PaginatingAndSortingProps) => {
    setDataFilter((dt) => ({ ...dt, ...pageAction }));
  }
  const titles = [
    { title: "Cupo", name: "Cupo.codigo" },
    { title: "Proveedor", name: "Proveedor.cedula" },
    /* { title: "Peso de Entrada (Kg)", sort: "peso_entrada_kg" },
    { title: "Peso Salida (kg)", sort: "peso_salida_kg" }, */
    { title: "Litros", sort: "cantidad" },
    { title: "Comentario", sort: "comentario" },
    { title: "Fecha", sort: "fecha" },
    { title: "Acciones", name: 'actions', sort: "tipo_estado" }
  ]

  return (
    <div>
      <h4 className="align-self-end">Pesos por día</h4>
      <div className="d-flex justify-content-center pt-4">
        <PlantaFechas runSearch={handleSubmit} />
      </div>
      <div className="d-flex justify-content-end pb-4">
        <div className="align-self-end">
          <button onClick={() => { setDatosPeso({ on: "add", data: undefined }); }} type="button" className="btn btn-primary btn-rounded mb-4" >
            Nuevo Peso
          </button>
        </div>
      </div>
      <div>
      </div>
      <h5 className="text-end bg-secondary rounded-3 px-4 py-1 text-white">Peso Total: {data.info?.pesajeTotal || '0.0'}</h5>
      <Table dataTable={{ ...data, titles, limit: 100 }} onChange={handleChangeTable} />
      <Modals title="Peso" show={!!datosPeso.on} onSave={onSaveModal} onClose={onCloseModal}>
        <PesoForm ref={pesoFormRef} data={datosPeso.data} on={datosPeso.on} />
      </Modals>
    </div>
  )
}
