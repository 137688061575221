import { Perfil } from "./Perfil";

interface Props {
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    showNav: boolean;
    screenIsSmall: boolean;
}
export const AppHeader = ({ setShowNav, showNav, screenIsSmall }:Props) => {
    return (
        <div className="conten-header d-flex justify-content-between align-items-center p-2">
            <div className="d-flex align-content-center col-sm-6 col-md-4 col">
                <button className="bt-hamburger" onClick={() => setShowNav(!showNav)}>
                    <div className="line-hamburger"></div>
                    <div className="line-hamburger"></div>
                    <div className="line-hamburger"></div>
                </button>
                {
                    !screenIsSmall &&
                    <img className="ms-4" style={{ width: '60%', maxWidth: '200px', height: 'auto' }} src="/LogoUvilorBlanco.png" alt="logo" />
                }
            </div>
            <div className="col-sm-6 col-md-4" /*style={{ maxWidth: '435px' }}*/>
                <i className="icon-search" style={{ position: 'absolute', padding: '11px 5px' }}></i>
                <input type="search" className="mx-auto w-100 form-control"
                    style={{ borderRadius: '8px', paddingLeft: '28px' }}
                    placeholder="Buscar..."
                />
            </div>
            <Perfil screenIsSmall={screenIsSmall} showBySmall={false}/>
        </div>
    )
}
