import { AuthUsrProps } from "./interfaces";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signIn, PropsUserLogin } from '../services/authService';
import TokenService from "../services/tokenService";
import { showMessage } from "./msgReducer";
import { hideSpiner, showSpiner } from "./spinerReducer";
//import { logout, login } from '../actions/auth';

const user: AuthUsrProps = TokenService.getUser();

const initialState = user
    ? user
    : { logged: false };



export const login = createAsyncThunk<
    AuthUsrProps,
    PropsUserLogin,
    {
        rejectValue: {
            errorMessage: string
        }
    }
>('users/login', async (user, thunkAPI) => {
    thunkAPI.dispatch(showSpiner());
    const rp = await signIn(user).then(data => {
        if (data?.accessToken) {
            data = { ...data, logged: true };
            TokenService.setUser(data);
            return data;
        }
    }).catch((e) => {
        thunkAPI.dispatch(showMessage({ body: e, type: "error" }));
    }).finally(() => thunkAPI.dispatch(hideSpiner()))
    return rp;
});
const authReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state) => {
            TokenService.removeUser();
            state.logged = false;
        }
    },
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, action) => {
            Object.assign(state, action.payload as AuthUsrProps)

        })
    }
})

export default authReducer.reducer;
export const { logout } = authReducer.actions;



