import { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import useForm from "../../hooks/useForm";
import { initialPlantas, propsPlantas, propsRutas, propsProveedores, initialProveedores } from '../../utils/formsInitialsStates';
import { cuposDisbonibles, getPlantas, getRutasByPlanta } from '../../services/adminServices';
import { useDoneFetchActions } from '../../hooks/useDoneFetchActions';
import { getBanco } from '../../services/catalogosServices';

interface Props {
  handleSubmit?: (values: propsProveedores) => void
  data?: propsProveedores,
  on?: string
}
export const ProveedorForm = forwardRef(({ handleSubmit, data, on }: Props, ref) => {
  const doneFetchActions = useDoneFetchActions();
  const { handleInputChange, formValues, resetForm, changeMulti, validateForm } = useForm<propsProveedores>(initialProveedores);
  const form = useRef<HTMLFormElement>(null);
  const [plantas, setPlantas] = useState([initialPlantas])
  const [rutas, setRutas] = useState([{ id: 0, nombre: '' }])
  const [cupos, setCupos] = useState([{ id: 0, codigo: '' }])
  const [bancos, setBancos] = useState([{ id: 0, nombre: '' }])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit && handleSubmit(formValues)
    return formValues;
  }
  useEffect(() => {
    const plantasBancos = Promise.all([getPlantas(), getBanco({ offset: 0, limit: 100 })])
    doneFetchActions(plantasBancos).then((resp: [[propsPlantas], { rows: [{ id: number; nombre: string }] }]) => {
      setPlantas(resp[0]);
      setBancos(resp[1]?.rows)
    })
  }, [doneFetchActions])

  useEffect(() => {
    if (formValues.id_planta) {
      doneFetchActions(getRutasByPlanta(formValues.id_planta)).then((resp: [propsRutas]) => {
        setRutas(resp)
      })
    }

  }, [formValues.id_planta, doneFetchActions])

  useEffect(() => {
    doneFetchActions(cuposDisbonibles(formValues.id_cupo)).then((resp) => {
      setCupos(resp)
    })
  }, [doneFetchActions, formValues.id_ruta])


  useImperativeHandle(ref, () => ({
    handleClick: () => {
      form.current?.requestSubmit();
      if (form.current && validateForm(form.current.elements)) {
        return formValues;
      }
    },
    resetForm: () => resetForm()
  }));
  useEffect(() => {
    if (data) changeMulti(data);
  }, [data, changeMulti])

  return (
    <form className="p-3" id='formBancos' ref={form} autoComplete="off" onSubmit={submitForm}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_planta" name="id_planta" value={formValues.id_planta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Planta</option>
              {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_planta">Planta</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_ruta" name="id_ruta" value={formValues.id_ruta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Ruta</option>
              {rutas.map(p => <option key={`ruta_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_ruta">Ruta</label>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_cupo" name="id_cupo" value={formValues.id_cupo} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione el cupo</option>
              {cupos.map(p => <option key={`cupo_${p.id}`} value={p.id}>{p.codigo}</option>)}
            </select>
            <label htmlFor="id_cupo">Cupo</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="cedula" name="cedula" value={formValues.cedula} onChange={handleInputChange}
              placeholder="-" data-trim="submit" required />
            <label htmlFor="cedula">Cédula</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="primer_nombre" name="primer_nombre" value={formValues.primer_nombre} onChange={handleInputChange}
              placeholder="-" data-type='string' data-capitalize="change" data-trim="submit" required />
            <label htmlFor="primer_nombre">Primer nombre</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="segundo_nombre" name="segundo_nombre" value={formValues.segundo_nombre} onChange={handleInputChange}
              placeholder="-" data-type='string' data-capitalize="change" data-trim="submit" />
            <label htmlFor="segundo_nombre">Segundo nombre</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="primer_apellido" name="primer_apellido" value={formValues.primer_apellido} onChange={handleInputChange}
              placeholder="-" data-type='string' data-capitalize="change" data-trim="submit" required />
            <label htmlFor="primer_apellido">Primer apellido</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="segundo_apellido" name="segundo_apellido" value={formValues.segundo_apellido} onChange={handleInputChange}
              placeholder="-" data-type='string' data-capitalize="change" data-trim="submit" />
            <label htmlFor="segundo_apellido">Segundo apellido</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="dv" name="dv" value={formValues.dv} onChange={handleInputChange}
              placeholder="-" data-type='string' data-capitalize="change" data-trim="submit" />
            <label htmlFor="dv">Dígito Verificador</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="estado" name="estado" value={Number(formValues.estado)} onChange={handleInputChange} required>
              <option value="" disabled>Seleccione un estado</option>
              <option value={1}>Activo</option>
              <option value={0}>Inactivo</option>
            </select>
            <label htmlFor="estado">Estado</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="precio_litro" name="precio_litro" value={formValues.precio_litro} onChange={handleInputChange}
              placeholder="-" data-double='change' data-capitalize="change" data-trim="submit" required />
            <label htmlFor="precio_litro">Precio</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="forma_pago" name="forma_pago" value={formValues.forma_pago} onChange={handleInputChange}>
              <option value='' disabled>Seleccione una forma de pago</option>
              <option value='ACH'>ACH</option>
              <option value='MNY'>Efectivo</option>
              <option value='CHQ'>Cheque</option>
            </select>
            <label htmlFor="segundo_apellido">Forma de Pago</label>
          </div>
        </div>
        {formValues.forma_pago === 'ACH' && <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_banco" name="id_banco" value={formValues.id_banco} onChange={handleInputChange} >
              <option value={0} disabled>Seleccione una Banco</option>
              {bancos.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_banco">Banco</label>
          </div>
        </div>}
      </div>
      {formValues.forma_pago === 'ACH' && <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="nombre_cuenta" name="nombre_cuenta" value={formValues.nombre_cuenta} onChange={handleInputChange}
              placeholder="-" data-type='alphanumeric' data-trim="submit" required />
            <label htmlFor="nombre_cuenta">Numero de Cuenta</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="tipo_cuenta" name="tipo_cuenta" value={formValues.tipo_cuenta} onChange={handleInputChange} required>
              <option value='' disabled>Seleccione una tipo de cuenta</option>
              <option value='AHO'>Ahorro</option>
              <option value='CRT'>Corriente</option>
            </select>
            <label htmlFor="tipo_cuenta">Tipo de Cuenta</label>
          </div>
        </div>
      </div>}
    </form >
  )
})
