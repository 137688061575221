import { useState, useCallback } from 'react';
import { formValidate, inputValidate } from './validations-forms';

export type FormElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface ReactSelectEvent {
    target: { name: string; value: string; type: string; };
}

type UseFormReturnType<T> = {
    formValues: T,
    handleInputChange: (event: React.ChangeEvent<FormElements> | ReactSelectEvent) => void,
    resetForm: () => void,
    validateForm: (frm: HTMLFormControlsCollection) => boolean
    changeMulti: (newValues: Partial<T>) => void
};

function useForm<T>(initialState: T): UseFormReturnType<T> {
    const [formValues, setFormValues] = useState<T>(initialState);
    function handleInputChange(event: React.ChangeEvent<FormElements> | ReactSelectEvent): void {
        if (event.target instanceof HTMLInputElement ||
            event.target instanceof HTMLSelectElement ||
            event.target instanceof HTMLTextAreaElement) inputValidate(event.target);
        const { name, value, type } = event.target;
        let newValue: string | boolean = value;
        if (type === 'checkbox') {
            newValue = (event.target as HTMLInputElement).checked;
        }
        setFormValues({ ...formValues, [name]: newValue });
    }

    function resetForm(): void {
        setFormValues(initialState);
    }

    function validateForm(frm: HTMLFormControlsCollection): boolean {
        return formValidate(frm);
    }

    
    const changeMulti = useCallback((newValues: Partial<T>) => {
        let aux = {} as  Partial<T>;
        for (let prop in newValues) {
            if (newValues[prop] !== null) {
                aux[prop] = newValues[prop];
            }
        }
        setFormValues(values => ({ ...values, ...aux }))
    }, [])

    return { formValues, handleInputChange, resetForm, validateForm, changeMulti };
}

export default useForm;
