import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SpinerAllContainer } from '../components/others/spiner/SpinerAllContainer';
import { PrivateRouter } from './PrivateRouter';
import { PublicRouter } from './PublicRouter';
import { Dashboard } from '../components/ui/Dashboard';
import { RootState, useAppDispatch, useAppSelector } from '../store/store';
import { useEffect } from 'react';
import './appRouter.css'
import { Login } from '../pages/login/Login';
import { hideMessage } from '../reducers/msgReducer';

export const AppRouter = () => {
    const st = useAppSelector(state => state) as RootState;
    const dispatch = useAppDispatch();
    const { auth, msg, spiner } = st;
    useEffect(() => {
        if (msg.isShow) {
            Swal.fire({
                title: msg.title,
                html: msg.body,
                confirmButtonText: 'Aceptar',
                icon: msg.type
            }).then(result => result.isConfirmed && dispatch(hideMessage()) )
        }
    }, [msg, dispatch])
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<PublicRouter isAuthenticated={auth?.logged} component={Login} />} />
                <Route path="/*" element={<PrivateRouter isAuthenticated={auth?.logged} component={Dashboard} />} />
            </Routes>
            <SpinerAllContainer isShow={spiner.isShow} />

        </BrowserRouter>
    )
}
