import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { initialPeso, initialPlantas, propsCupo, propsPeso, propsPlantas } from '../../utils/formsInitialsStates';
import { useDoneFetchActions } from '../../hooks/useDoneFetchActions';
import useForm from '../../hooks/useForm';
import { getCuposByPlanta, getPlantas } from '../../services/adminServices';
import Select from 'react-select'

interface Props {
  handleSubmit?: (values: propsPeso) => void;
  data?: propsPeso,
  on?: string
}
export const PesoForm = forwardRef(({ handleSubmit, data, on }: Props, ref) => {
  const doneFetchActions = useDoneFetchActions();
  const { handleInputChange, formValues, resetForm, changeMulti, validateForm } = useForm<propsPeso>(initialPeso);
  const form = useRef<HTMLFormElement>(null);
  const [plantas, setPlantas] = useState([initialPlantas])
  const [cupos, setCupos] = useState<Array<{ value: string; label: string; }>>()

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit && handleSubmit(formValues)
  }

  useEffect(() => {
    doneFetchActions(getPlantas()).then((resp: [propsPlantas]) => {
      setPlantas(resp);
    })
  }, [doneFetchActions])


  useEffect(() => {
    if (formValues.id_planta) {
      doneFetchActions(getCuposByPlanta({ planta: Number(formValues.id_planta) })).then((resp: [propsCupo]) => {
        setCupos(resp.map(ob => ({ value: String(ob.id), label: String(ob.codigo) })))
      })
    }
  }, [formValues.id_planta, doneFetchActions])

  useImperativeHandle(ref, () => ({
    handleClick: () => {
      form.current?.requestSubmit();
      if (form.current && validateForm(form.current.elements)) {
        return formValues;
      }
    },
    resetForm: () => {
      if (on === "add") {
        changeMulti({ id_cupo: undefined, cantidad: '' })
        return;
      }
      resetForm();
    },
  }));
  useEffect(() => {
    if (data) changeMulti(data);
  }, [data, changeMulti])

  return (
    <form className="p-3" ref={form} autoComplete="off" onSubmit={submitForm}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_planta" name="id_planta" value={formValues.id_planta} onChange={handleInputChange} disabled={on !== "add"} required>
              <option value="" disabled>Seleccione una Planta</option>
              {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_planta">Planta</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <Select
              className='form-select'
              placeholder='Seleccione el cupo'
              classNamePrefix='react-select-btsp'
              noOptionsMessage={() => 'Cupos no encontrados'}
              isClearable options={cupos}
              value={cupos?.find(cp => cp.value === String(formValues.id_cupo)) || { value: '' }}
              onChange={(a) => {
                const target = document.getElementById('id_cupo') as HTMLInputElement;
                target.value = a?.value || '';
                handleInputChange({ target })
              }}
              isDisabled={on !== "add"}
            />
            <label htmlFor="id_cupo">Cupo</label>
            <input type='hidden' name='id_cupo' id='id_cupo' value={formValues.id_cupo} required disabled={on !== "add"} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="peso_entrada_lts" name="peso_entrada_lts" value={formValues.peso_entrada_lts} onChange={handleInputChange}
              placeholder="-" data-uppercase="change" data-trim="change" disabled />
            <label htmlFor="peso_entrada_lts">Litros de Entrada</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="peso_salida_lts" name="peso_salida_lts" value={formValues.peso_salida_lts} onChange={handleInputChange}
              placeholder="-" data-uppercase="change" data-trim="change" disabled />
            <label htmlFor="peso_salida_lts">Litros de Salida</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="cantidad" name="cantidad" value={formValues.cantidad} onChange={handleInputChange}
              placeholder="-" data-min='1' data-uppercase="change" data-trim="change" required />
            <label htmlFor="cantidad">Litros</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="tipo_estado" name="tipo_estado" value={formValues.tipo_estado} onChange={handleInputChange} >
              <option value={0} >Seleccione una estado</option>
              <option value={10} >Pendiente de resolver</option>
              <option value={15} >Resuelto</option>
            </select>
            <label htmlFor="cantidad">Estado del Comentario</label>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <input type='date' className="form-control" id="fecha" name="fecha" value={formValues.fecha?.substring(0, 10)} onChange={handleInputChange} />
            <label htmlFor="fecha">Fecha</label>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <div className="form-floating mb-3">
            <textarea className="form-control" id="comentario" name="comentario" value={formValues.comentario} onChange={handleInputChange}
              placeholder="-" data-trim="submit" />
            <label htmlFor="comentario">Comentario</label>
          </div>
        </div>
      </div>
    </form>
  )
})
