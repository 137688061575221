import { TitleTableProps } from '../interfaces/interfaces';

interface props {
    rows?: Array<{ [key: string]: any }>;
    titles?: Array<TitleTableProps>;
    rowsToShow: number;
    page: number;
    offset: number;
}

export const useTbody = ({ rows, titles, rowsToShow, page, offset }: props) => {
    const initPage = (page * rowsToShow) - offset;
    const destruc = (obj: { [key: string]: any }, sort: Array<string>, x: number) => {
        let { [sort[x]]: val = '' } = obj || {};
        if (x < sort.length - 1) {
            val = destruc(val, sort, x + 1);
        }
        return val;
    }
    if (titles) {
        return rows?.slice(initPage, initPage + rowsToShow).map((obj) =>
            titles.map(({ sort, name }) =>
                !Array.isArray(name)
                    ? destruc(obj, name?.split(".") || sort?.split(".") || [""], 0)
                    : name.map(x => destruc(obj, x.split("."), 0)).join(' ')
            )
        );
    }
    return rows?.slice(initPage, initPage + rowsToShow).map((obj) =>
        Object.entries(obj).map(col => col[1])
    );
}
