import { Navigate } from 'react-router';

interface props {
  isAuthenticated: boolean;
  component: () => JSX.Element;
}
export const PrivateRouter = ({ isAuthenticated, component: Component, ...rest }: props) => {
  return (
    <>
      {isAuthenticated
        ? <Component {...rest} />
        : <Navigate to="/login" />
      }
    </>
  )
}
