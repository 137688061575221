import { logout } from '../../reducers/authReducer';
import { useAppSelector, useAppDispatch } from '../../store/store';

export const Perfil = ({ screenIsSmall, showBySmall }: { screenIsSmall: boolean, showBySmall: boolean }) => {
  const { primer_nombre, primer_apellido } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch()
  return (
    <>
      {screenIsSmall === showBySmall && (screenIsSmall ? (
        <div className="d-flex align-items-center flex-column mt-2 mb-4" style={{ color: '#fff', textAlign: 'center' }}>
          <div className="d-flex justify-content-center mt-4">
            <div className="perfil mx-2">
              <img src="/User_icon_2.png" alt="logo" />
            </div>
            <div className="text-light text-start">
              <div>
                <span>{`${primer_nombre} ${primer_apellido}`}</span>
                <br />
                <a href='/#' className='text-danger text-decoration-none' onClick={(e) => { e.preventDefault(); dispatch(logout()); }}><span className="icon-switch"></span>salir</a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex col-md-4 justify-content-end">
          <div className="text-light text-end">
            <div>
              <span>{`${primer_nombre} ${primer_apellido}`}</span>
              <br />
              <a href='/#' className='text-danger text-decoration-none' onClick={(e) => { e.preventDefault(); dispatch(logout()); }}><span className="icon-switch"></span>salir</a >
            </div>
          </div>
          <div className="perfil mx-2">
            <img src="/User_icon_2.png" alt="logo" />
          </div>
        </div>
      ))}
    </>
  )
}
