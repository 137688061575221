import { createContext, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RolRouter } from '../../router/RolRouter';
import { SCOPES_BY_ROLES } from '../../utils/ROLES';
import { AppHeader } from './AppHeader';
import { NavBar2 } from './NavBar2';
import { useAppSelector } from '../../store/store';
import { LitrosPorMeses } from '../../pages/LitrosPorMeses';
import { Home } from '../../pages/Home';
import { Bancos } from '../../pages/Bancos';
import { Reportes } from '../../pages/Reportes';
import { Proveedores } from '../../pages/Proveedores';
import { Rutas } from '../../pages/Rutas';
import { Conductores } from '../../pages/Conductores';
import { Cupos } from '../../pages/Cupos';
import { Pesos } from '../../pages/Pesos';
import { PagoConductores } from '../../pages/PagoConductores';


interface TitleContexProps {
    titlesPage: TitlesPageProps;
    setTitlesPage: React.Dispatch<React.SetStateAction<TitlesPageProps>>
}
export interface TitlesPageProps {
    title: string;
    subTitle: string;
}

export const TitleContex = createContext({} as TitleContexProps);
export const Dashboard = () => {
    const rol = useAppSelector(state => state.auth.rol);
    const [titlesPage, setTitlesPage] = useState({} as TitlesPageProps)
    const [showNav, setShowNav] = useState(false);
    const [screenIsSmall, setScreenIsSmall] = useState(document.body.clientWidth < 768);
    window.addEventListener('resize', () => {
        setScreenIsSmall(document.body.clientWidth < 768);
    });
    useEffect(() => {
      
    }, [])
    
    return (
        <div>
            <AppHeader setShowNav={setShowNav} showNav={showNav} screenIsSmall={screenIsSmall} />
            <NavBar2 setShowNav={setShowNav} showNav={showNav} screenIsSmall={screenIsSmall} />
            <div id='main' style={{ zIndex: 0 }} className="mx-5">
                <TitleContex.Provider value={{ titlesPage, setTitlesPage }}>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/rutas" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={Rutas} />}
                        />
                        <Route path="/conductores" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={Conductores} />}
                        />
                        <Route path="/catalogo-banco" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={Bancos} />}
                        />
                        <Route path="/cupos" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={Cupos} />}
                        />
                        <Route path="/proveedores" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={Proveedores} />}
                        />
                        <Route path="/pesos" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_1} userRoles={rol} component={Pesos} />}
                        />
                        <Route path="/reporte-mida" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={Reportes} />}
                        />
                        <Route path="/litros-por-meses" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={LitrosPorMeses} />}
                        />
                        <Route path="/pago-conductores" element={
                            <RolRouter needScope={SCOPES_BY_ROLES.SCP_2} userRoles={rol} component={PagoConductores} />}
                        />
                    </Routes>
                </TitleContex.Provider>
            </div>
        </div>
    )
}

