import React, { useContext } from 'react';
import { PaginationComponentProps } from '../interfaces/interfaces'
import { TableContext } from './Table';
import styles from '../styles/styles.module.css';

export const Pagination = ({ className, style }: PaginationComponentProps) => {

    const { handeChangePaginatingAndSorting, pageAction, setPage, dataTable, page } = useContext(TableContext);
    const { showLastedAndFirst, itemPaginationShow, count, rowsToShow, limit = 255, } = dataTable;
    const totalPages = Math.ceil(count / rowsToShow);
    const items = Math.min(itemPaginationShow || 3, totalPages);
    const sumar = Math.max(0, page - Math.floor(items / 2));

    const handleChangePage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, i: number) => {
        e.preventDefault();
        const nexPage = i <= 0 ? 0 : (i >= totalPages ? totalPages - 1 : i);
        const ctSearch = Math.floor(count / limit <= count ? limit : 1);
        const seccionActual = Math.floor(nexPage / ctSearch)
        if (seccionActual !== Math.floor(page / ctSearch)) {
            handeChangePaginatingAndSorting({ ...pageAction, offset: seccionActual * limit, limit })
        }
        setPage(nexPage);
    }
    return (
        <ul className={`${styles.pagination} ${className && className}`} style={style}>
            {sumar > 0
                && <li className={styles.pg_item}>
                    <a className={styles.pg_link} href='/#'
                        onClick={(e) => handleChangePage(e, 0)}
                    >1...</a>
                </li>}
            {showLastedAndFirst && items > 1 && page >= items
                && <li className={styles.pg_item}>
                    <a className={styles.pg_link} href='/#' onClick={(e) => handleChangePage(e, page - items)}>{'«'}</a>
                </li>}
            {page > 0
                && <li className={styles.pg_item}>
                    <a className={styles.pg_link} href='/#' onClick={(e) => handleChangePage(e, page - 1)}>{'<'}</a>
                </li>}
            {Array.apply(null, Array(items)).map((_ob, i) =>
                i + sumar < totalPages &&
                <li className={styles.pg_item} key={i + '_itPg_' + sumar} >
                    <a className={`${styles.pg_link} ${page === i + sumar ? styles.active : ''}`}
                        href='/#'
                        onClick={(e) => handleChangePage(e, i + sumar)}
                    >
                        {i + 1 + sumar}
                    </a>
                </li>
            )}


            {page < totalPages - 1
                && <li className={styles.pg_item}>
                    <a className={styles.pg_link} href='/#' onClick={(e) => handleChangePage(e, page + 1)}>{'>'}</a>
                </li>}
            {showLastedAndFirst && items > 1 && page < totalPages - items
                && <li className={styles.pg_item}>
                    <a className={styles.pg_link} href='/#' onClick={(e) => handleChangePage(e, page + items)}>{'»'}</a>
                </li>}
            {sumar + items < totalPages
                && <li className={styles.pg_item}>
                    <a className={styles.pg_link} href='/#'
                        onClick={(e) => handleChangePage(e, totalPages - 1)}
                    >...{totalPages}</a>
                </li>}
        </ul>
    )
}

