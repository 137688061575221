import { AuthUsrProps } from '../reducers/interfaces';


const myStorage = {} as { usr: AuthUsrProps };

const TokenService = {
    getLocalRefreshToken() {
        const user = myStorage.usr || JSON.parse(localStorage.getItem("usr") || "null");
        return String(user?.refreshToken);
    },
    getLocalAccessToken() {
        const user: AuthUsrProps = JSON.parse(localStorage.getItem("usr") || "null");
        return user?.accessToken;
    },
    updateLocalAccessToken(token: string) {
        let user: AuthUsrProps = JSON.parse(localStorage.getItem("usr") || "null");
        user.accessToken = token;
        this.setUser(user);
    },
    getUser() {
        //JSON.parse(localStorage.getItem('usr') || '{"logged": false}')
        return myStorage.usr || JSON.parse(localStorage.getItem("usr") || "{}") as AuthUsrProps;
    },
    setUser(user: AuthUsrProps) {
        const exp = JSON.parse(window.atob(user.accessToken?.split('.')[1] || "")).exp;
        myStorage.usr = { ...user, exp };
        localStorage.setItem("usr", JSON.stringify(myStorage.usr));
    },
    getExp() {
        const user: AuthUsrProps = myStorage.usr || JSON.parse(localStorage.getItem("usr") || "null");
        return user?.exp || 0;
    },
    removeUser() {
        myStorage.usr = {} as AuthUsrProps;
        localStorage.removeItem("usr");
    }
}
export default TokenService;