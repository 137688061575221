import { NavLink } from 'react-router-dom';
import { Perfil } from './Perfil';
import { Accordion, Card } from 'react-bootstrap';
interface Props {
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
  showNav: boolean;
  screenIsSmall: boolean;
}
export const NavBar2 = ({ setShowNav, showNav, screenIsSmall }: Props) => {
  const width = showNav ? '16rem' : '0';
  return (
    <div className="zindex-fixed d-flex position-fixed bg-uvilor-g">
      <nav className="nav-lateral d-flex flex-column bg-uvilor-g" style={{ width: width }}>
        <Perfil screenIsSmall={screenIsSmall} showBySmall={true} />
        <Accordion className="bg-uvilor-g border-0 nav-accordion">
          <Accordion.Item eventKey="0" className="bg-uvilor-g border-0">
            <Accordion.Button as={Card.Header} className="bg-uvilor-g text-white accordion-header">
              <span className="icon-user-admin-svgrepo-com pe-2"> </span>Administración
            </Accordion.Button>
            <Accordion.Body >
              <ul>
                <li className="accordion-body">
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="rutas"
                  ><span className="icon-tank-truck-svgrepo-com"> </span>Rutas
                  </NavLink>
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="conductores"
                  ><span className="icon-driver-svgrepo-com-1"> </span>Conductores
                  </NavLink>
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="cupos"
                  ><span className="icon-id-card-svgrepo-com"> </span>Cupos
                  </NavLink>
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="proveedores"
                  ><span className="icon-wine-maker-wine-producer-wine-making-svgrepo-com"> </span>Proveedores
                  </NavLink>
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="pesos"
                  ><span className="icon-weight-weigh-scale-svgrepo-com"> </span>Pesos
                  </NavLink>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="bg-uvilor-g border-0">
            <Accordion.Button as={Card.Header} className="bg-uvilor-g text-white accordion-header">
              <span className="icon-report-svgrepo-com pe-2"> </span>Reportes
            </Accordion.Button>
            <Accordion.Body>
              <ul>
                <li className="accordion-body">
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="/litros-por-meses"
                  ><span className="icon-report-svgrepo-com-1"> </span>Litros Por Meses
                  </NavLink>
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="reporte-mida"
                  ><span className="icon-profile"> </span> Rutas por fechas
                  </NavLink>
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="pago-conductores"
                  ><span className="icon-driver"> </span> Pago Conductores
                  </NavLink>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="bg-uvilor-g border-0">
            <Accordion.Button as={Card.Header} className="bg-uvilor-g text-white accordion-header">
              <span className="icon-library-svgrepo-com pe-2"> </span>Catálogos
            </Accordion.Button>
            <Accordion.Body>
              <ul>
                <li className="accordion-body">
                  <NavLink
                    onClick={() => setShowNav(false)}
                    className={({ isActive }) => `text-decoration-none ${isActive && 'active'}`} to="catalogo-banco"
                  ><span className="icon-bank-svgrepo-com"> </span>Banco
                  </NavLink>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {screenIsSmall && <div className="mt-auto d-flex mb-2">
          <NavLink
            to="/"
            title="Inicio"
            className={({ isActive }) => `text-decoration-none m-auto ${isActive && 'active'}`}
          ><img className="mt-4 mb-5" style={{ maxWidth: '200px', height: 'auto' }} src="/LogoUvilorBlanco.png" alt="logo" />
          </NavLink>
        </div>}
      </nav>

      {
        showNav && <div
          className="position-absolute"
          onClick={() => setShowNav(false)}
          style={{ backgroundColor: '#47474760', transition: 'opacity .8s', width: '100vw', height: '100vh' }}
        ></div>
      }
    </div >
  )
}