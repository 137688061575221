import TokenService from "../services/tokenService";
import { MS_FORBIDDEN_ERROR, MS_INTERNAL_ERROR, MS_NOT_FOUND_ERROR, MS_SESSION_EXPIRED, MS_UNAUTHORIZED_ERROR, MS_UNKNOWN_ERROR, TOKEN_EXPIRED } from "./Constant";
import { getCookie } from './utility';

interface NewRequestInit extends RequestInit {
    objectBody?: JsonProps
}
type JsonProps = { [key: string]: any };
/**
 * @param url string 
 * @param options NewRequestInit
 * @param urlQuery JsonProps
 */
export const fetchRS = async (url: string, options?: NewRequestInit, urlQuery?: JsonProps,) => {
    if (TokenService.getExp() * 1000 < new Date().getTime() && !url.includes("/signin")) {
        const newToken = (await refreshTokenPTr() as { [key: string]: string })?.accessToken;
        if (newToken) {
            TokenService.updateLocalAccessToken(newToken);
        } else {
            return Promise.reject(MS_SESSION_EXPIRED);
        }
    }
    const queryString = urlQuery ? '?' + Object.entries(urlQuery).map(key => key[0] + '=' + key[1]).join('&') : '';
    const { objectBody, headers, ...rest } = options || {};
    const _options = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
            "authorization": `Bearer ${TokenService.getLocalAccessToken() || ''}`,
            'device_id': getCookie("device_id"),
            ...headers
        },
        body: JSON.stringify(objectBody),
        ...rest
    }

    async function geResp(resp: Response) {
        const contentType = resp.headers.get('Content-Type');
        if (contentType?.includes('application/json')) {
            return resp.json()
        }
        const file = contentType?.match(/file=(.+)/)?.[1];
        if (file) {
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        }
    }
    return fetch(url.concat(queryString), _options)
        .then(async (resp) => {
            const rp = await geResp(resp);
            switch (resp.status) {
                case 200:
                    return rp;
                case 401:
                    throw (rp.message?.includes(TOKEN_EXPIRED) ? MS_SESSION_EXPIRED : rp.message || MS_UNAUTHORIZED_ERROR);
                case 500:
                    throw rp.message || MS_INTERNAL_ERROR;
                case 404:
                    throw rp.message || MS_NOT_FOUND_ERROR;
                case 403:
                    throw rp.message || MS_FORBIDDEN_ERROR;
                default:
                    throw rp.message || MS_UNKNOWN_ERROR;
            }
        })/* .catch(e => {
            throw e || MS_UNKNOWN_ERROR
        }) */
}

const refreshTokenPTr = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/login/refreshtoken`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
            "authorization": `Bearer ${TokenService.getLocalAccessToken() || ''}`,
            'device_id': getCookie("device_id")
        },
        body: JSON.stringify({ refreshToken: TokenService.getLocalRefreshToken(), user_id: TokenService.getUser().id }),
    })
        .then(res => res.json())
        .then(data => data)
        .catch(() => { });
}