import { useEffect, useCallback, useState, useRef } from 'react';
import { Table } from '../components/table-pagination-sorting/components/Table';
import { PaginatingAndSortingProps } from '../components/table-pagination-sorting/interfaces/interfaces';
import { useDoneFetchActions } from '../hooks/useDoneFetchActions';
import useForm from '../hooks/useForm';
import { createBanco, getBanco } from '../services/catalogosServices';
import { initialBanco, propsBanco, tablePaginationRs } from '../utils/formsInitialsStates';

export const Bancos = () => {

  const doneFetchActions = useDoneFetchActions();
  const [data, setData] = useState({ count: 0 } as { count: number, rows: Array<{ [key: string]: any }> })
  const updateTabla = useCallback((pagination: PaginatingAndSortingProps) => {
    doneFetchActions(getBanco(pagination))
      .then((data: tablePaginationRs) => {
        data.rows = data.rows?.map(ob => ({
          ...ob,
          estado: <div className="w-100"><input type='checkbox' checked={ob.estado} disabled /></div>,
        }))
        setData(ant => ({ ...ant, ...data }));
      });
  }, [doneFetchActions])

  useEffect(() => {
    updateTabla({} as PaginatingAndSortingProps)
  }, [updateTabla])


  const handleChangeTable = (pageAction: PaginatingAndSortingProps) => {
    updateTabla(pageAction);
  }
  const titles = [
    /* {
      title: "Cóigo",
      sort: "codigo"
    }, */
    {
      title: "Nombre",
      sort: "nombre"
    },
    {
      title: "Estado",
      sort: "estado"
    }
  ]

  const form = useRef<HTMLFormElement>(null);
  const { formValues, handleInputChange, resetForm, validateForm } = useForm<propsBanco>(initialBanco);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm(event.currentTarget.elements)) {
      doneFetchActions(createBanco(formValues))
        .then(data => {
          resetForm();
        });
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <h4 className="align-self-end">Bancos</h4>
      </div>
      <div>
        <button type="button" className="btn btn-primary btn-rounded mb-4" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Crear nuevo
        </button>
        <Table dataTable={{ ...data, titles, limit: 100 }} onChange={handleChangeTable} />
      </div>

      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form className="p-3" id='formBancos' ref={form} autoComplete="off" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={formValues.id} onChange={handleInputChange} />

                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="nombre" name="nombre" value={formValues.nombre} onChange={handleInputChange}
                    placeholder="-" data-type='alphanumeric' data-min={3} data-trim="submit" required />
                  <label htmlFor="nombre">Nombre</label>
                </div>

                {/* <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="codigo" name="codigo" value={formValues.codigo} onChange={handleInputChange}
                    placeholder="-" data-type='alphanumeric' data-uppercase="change" data-trim="submit" required />
                  <label htmlFor="codigo">Código</label>
                </div> */}

                <div className="form-floating mb-3">
                  <select className="form-select" id="estado" name="estado" value={+formValues.estado} onChange={handleInputChange} required>
                    <option value="" disabled>Seleccione un estado</option>
                    <option value={1}>Activo</option>
                    <option value={0}>Inactivo</option>
                  </select>
                  <label htmlFor="estado">Estado</label>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={() => form.current?.requestSubmit()}>Crear Banco</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
