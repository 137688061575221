import { SpinerProps } from "./interfaces";
import { createSlice } from "@reduxjs/toolkit";


const initialState: SpinerProps = {
    isShow: false,
}


const spinerReducer = createSlice({
    name: 'spiner',
    initialState,
    reducers: {
        showSpiner: (state) => {
            state.isShow = true

        },
        hideSpiner: (state) => {
            state.isShow = false;
        }
    }
})

export default spinerReducer.reducer;
export const { hideSpiner, showSpiner } = spinerReducer.actions;