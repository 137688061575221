import { Modals } from "../components/Modals";
import { ConductorForm } from "../components/forms-modals/ConductorForm";
import { Table } from "../components/table-pagination-sorting/components/Table";
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { useAppDispatch } from "../store/store";
import { useState, useRef, useEffect, useCallback } from 'react';
import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { initialConductor, propsConductor, tablePaginationRs } from "../utils/formsInitialsStates";
import { showMessage } from "../reducers/msgReducer";
import { getConductor, getConductores, savetConductor } from "../services/adminServices";

export const Conductores = () => {

  const doneFetchActions = useDoneFetchActions();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({ count: 0 } as { count: number, rows: Array<{ [key: string]: any }> })
  //const [pagination, setPagination] = useState({} as PaginatingAndSortingProps)
  const [datosConductor, setDatosConductor] = useState({} as { on: string, data: propsConductor | undefined })

  const conductorFormRef = useRef<{ handleClick: () => propsConductor; resetForm: () => void; }>();
  const handelPagination = useCallback((pagination: PaginatingAndSortingProps) => {
    function searchDataConductor(tipe: string, id: number) {
      doneFetchActions(getConductor(id)).then((resp: propsConductor) => {
        setDatosConductor({ on: tipe, data: resp });
      })
    }
    doneFetchActions(getConductores(pagination)).then((data: tablePaginationRs) => {
      data.rows = data.rows?.map(ob => ({
        ...ob,
        estado: <div className="w-100 text-center"><input type='checkbox' checked={ob.estado} disabled /></div>,
        actions: <>
          <button className='btn' onClick={() => searchDataConductor("edit", ob.id)}><span className="icon-create"></span></button>
          {/* <button className='btn' value={ob.id} data-bs-toggle="modal" data-bs-target="#ConductorModelsForm" onClick={() => searchDataConductor("view", ob.id)}><span className="icon-remove_red_eye"></span></button> */}
        </>
      }))
      setData(ant => ({ ...ant, ...data }));
    })
  }, [doneFetchActions]);

  const onSaveModal = () => {
    const conductorValues = conductorFormRef.current?.handleClick();
    if (conductorValues) {
      doneFetchActions(savetConductor(conductorValues)).then(resp => {
        dispatch(showMessage({
          isShow: true,
          title: "Conductor Guardado satisfactoriamente",
          type: 'success'
        }))
        handelPagination({} as PaginatingAndSortingProps);
        setDatosConductor({ on: "", data: initialConductor });
        conductorFormRef.current?.resetForm();
      })
    }
  }
  useEffect(() => {
    handelPagination({} as PaginatingAndSortingProps)
  }, [handelPagination])

  const onCloseModal = () => {
    conductorFormRef.current?.resetForm();
    setDatosConductor({ ...datosConductor, on: '' })
  }

  const titles = [
    { title: "Cédula", sort: "cedula" },
    { title: "Nombre", name: ['primer_nombre', 'primer_apellido'], sort: 'primer_nombre' },
    { title: "Ruta", name: "Rutum.nombre" },
    { title: "DV", sort: "dv" },
    { title: "Estado", sort: "estado" },
    { title: "Acciones", name: "actions" }
  ]
  /**
   tipo_pago:'PL',
  pago_litros: 0,
  pago_fijo: 0,
   */
  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <h4 className="align-self-end">Conductores</h4>
      </div>
      <div className="d-flex justify-content-end pb-4">
        <div className="align-self-end">
          <button onClick={() => { setDatosConductor({ on: "add", data: undefined }); }} type="button" className="btn btn-primary btn-rounded mb-4" >
            Nuevo Conductor
          </button>
        </div>
      </div>
      <div>
      </div>
      <Table dataTable={{ ...data, titles, limit: 100 }} onChange={handelPagination} />
      <Modals title="Crear Conductor" onSave={onSaveModal} onClose={onCloseModal} show={!!datosConductor.on}>
        <ConductorForm ref={conductorFormRef} data={datosConductor.data} on={datosConductor.on} />
      </Modals>
    </div>
  )
}
