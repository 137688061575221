import { useCallback } from "react";
import { logout } from "../reducers/authReducer";
import { showMessage } from "../reducers/msgReducer";
import { hideSpiner, showSpiner } from "../reducers/spinerReducer";
import { useAppDispatch } from "../store/store";
import { MS_FORBIDDEN_ERROR, MS_SESSION_EXPIRED } from "../utils/Constant";


export const useDoneFetchActions = () => {
    const dispatch = useAppDispatch();
    const doneFetchActions = useCallback((service: Promise<any>) => {
        dispatch(showSpiner());
        return service
            .catch(e => {
                if (e === MS_SESSION_EXPIRED || e === MS_FORBIDDEN_ERROR) {
                    dispatch(logout());
                }
                dispatch(showMessage({ body: e, type: "error" }));
                return Promise.reject(e);
            })
            .finally(() => dispatch(hideSpiner()));
    },[dispatch]);
    return doneFetchActions;
}