import { Provider } from "react-redux";
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppRouter } from "./router/AppRouter";
import { store } from './store/store';
import { checkCookie, setCookie } from './utils/utility';

function App() {
  useEffect(() => {
    if (!checkCookie("device_id")) {
      setCookie("device_id", uuidv4())
    }
  }, [])

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
