import { Modals } from "../components/Modals";
import { CupoForm } from "../components/forms-modals/CupoForm";
import { Table } from "../components/table-pagination-sorting/components/Table";
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { useAppDispatch } from "../store/store";
import { useState, useRef, useEffect, useCallback } from 'react';
import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { initialCupo, propsCupo, tablePaginationRs } from '../utils/formsInitialsStates';
import { showMessage } from "../reducers/msgReducer";
import { getCupo, getCupos, saveCupo } from "../services/adminServices";

export const Cupos = () => {

  const doneFetchActions = useDoneFetchActions();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({ count: 0 } as { count: number, rows: Array<{ [key: string]: any }> })
  const [dataCupo, setDataCupo] = useState({} as { on: string, data: propsCupo | undefined })

  const cupoFormRef = useRef<{ handleClick: () => propsCupo; resetForm: () => void; }>();
  const handelPagination = useCallback((pagination: PaginatingAndSortingProps) => {
    function searchDataCupo(tipe: string, id: number) {
      doneFetchActions(getCupo(id)).then((resp: propsCupo) => {
        setDataCupo({ on: tipe, data: resp });
      })
    }
    doneFetchActions(getCupos(pagination)).then((data: tablePaginationRs) => {
      data.rows = data.rows?.map(ob => ({
        ...ob,
        estado: <div className="w-100 text-center"><input type='checkbox' checked={ob.estado} disabled /></div>,
        actions: <>
          <button className='btn' onClick={() => searchDataCupo("edit", ob.id)}><span className="icon-create"></span></button>
          {/* <button className='btn' value={ob.id} data-bs-toggle="modal" data-bs-target="#CupoModelsForm" onClick={() => searchDataCupo("view", ob.id)}><span className="icon-remove_red_eye"></span></button> */}
        </>
      }))
      setData(ant => ({ ...ant, ...data }));
    })
  }, [doneFetchActions]);

  const onSaveModal = () => {
    const conductorValues = cupoFormRef.current?.handleClick();
    if (conductorValues) {
      doneFetchActions(saveCupo(conductorValues)).then(_resp => {
        dispatch(showMessage({
          isShow: true,
          title: "Cupo Guardado Satisfactoriamente ",
          type: 'success'
        }))
        handelPagination({} as PaginatingAndSortingProps);
        setDataCupo({ on: "", data: initialCupo });
        cupoFormRef.current?.resetForm();
      })
    }
  }
  useEffect(() => {
    handelPagination({} as PaginatingAndSortingProps)
  }, [handelPagination])

  const onCloseModal = () => {
    cupoFormRef.current?.resetForm();
    setDataCupo({ ...dataCupo, on: '' })
  }

  const titles = [
    { title: "Planta", sort: "Rutum.Plantum.nombre" },
    { title: "Ruta", sort: "Rutum.nombre" },
    { title: "Código", sort: 'codigo' },
    { title: "Estado", sort: "estado" },
    { title: "Cédula", name: "Proveedor.cedula" },
    { title: "Acciones", name: "actions" }
  ]
  /**
   tipo_pago:'PL',
  pago_litros: 0,
  pago_fijo: 0,
   */
  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <h4 className="align-self-end">Cupos</h4>
      </div>
      <div className="d-flex justify-content-end pb-4">
        <div className="align-self-end">
          <button onClick={() => { setDataCupo({ on: "add", data: undefined }); }} type="button" className="btn btn-primary btn-rounded mb-4" >
            Nuevo Cupo
          </button>
        </div>
      </div>
      <div>
      </div>
      <Table dataTable={{ ...data, titles, limit: 100 }} onChange={handelPagination} />
      <Modals title="Cupo" show={!!dataCupo.on} onSave={onSaveModal} onClose={onCloseModal}>
        <CupoForm ref={cupoFormRef} data={dataCupo.data} on={dataCupo.on} />
      </Modals>
    </div>
  )
}
