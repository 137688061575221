import { createContext, useState } from 'react';
import { PaginatingAndSortingProps, TableProps, TableContextProps, InternalDataTableProps } from '../interfaces/interfaces';
import { Pagination } from './Pagination';
import { THead } from './THead';
import { TBody } from './TBody';
import styles from '../styles/styles.module.css';

export const TableContext = createContext({} as TableContextProps);
const { Provider } = TableContext;


export const Table = ({ dataTable, children, onChange, className, classTable, classPagination, style }: TableProps) => {
    const [page, setPage] = useState(0)
    const [pageAction, setPageAction] = useState({
        offset: 0,
        order: '',
        sort: '',
        limit: dataTable.limit || 100
    });
    const handeChangePaginatingAndSorting = (_pageAction: PaginatingAndSortingProps) => {
        setPageAction(_pageAction);
        onChange(_pageAction);
    }

    const _dataTable = {
        showPagination: true,
        showLastedAndFirst: true,
        limit: 255,
        rowsToShow: 10,
        ...dataTable
    } as InternalDataTableProps;
    return (
        <Provider value={{ handeChangePaginatingAndSorting, pageAction, dataTable: _dataTable, page, setPage }}>
            <div className={className && className} style={style}>
                <table className={`${styles.table} ${styles.table_hover} ${classTable && className}`}>
                    {
                        children
                            ? children
                            : (
                                <>
                                    {_dataTable.titles ? <THead /> : <></>}
                                    {_dataTable.rows ? <TBody /> : <></>}
                                </>
                            )
                    }
                </table>
                {
                    _dataTable.showPagination && <Pagination className={classPagination}/>
                }
            </div>
        </Provider>
    )
}



/* Table.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    className: PropTypes.string,
    classPagination: PropTypes.string,
    classTable: PropTypes.string,
    onChange: PropTypes.func,
    dataTable: PropTypes.shape({
        page: PropTypes.arrayOf(PropTypes.object),
        itemPaginationShow: PropTypes.number,
        number: PropTypes.number.isRequired,
        showLastedAndFirst: PropTypes.bool,
        showPagination: PropTypes.bool,
        titles: PropTypes.arrayOf(PropTypes.shape({
            sort: PropTypes.string,
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
            title: PropTypes.string.isRequired,
        })),
        totalElements: PropTypes.number,
        totalPages: PropTypes.number.isRequired
    }),
    style: PropTypes.object,
} */
