import { useRef, useState, useCallback, useEffect } from 'react';
import { Modals } from '../components/Modals';
import { ConductorForm } from '../components/forms-modals/ConductorForm';
import { RutaForm } from '../components/forms-modals/RutaForm';
import { Table } from '../components/table-pagination-sorting/components/Table';
import { PaginatingAndSortingProps } from '../components/table-pagination-sorting/interfaces/interfaces';
import { useDoneFetchActions } from '../hooks/useDoneFetchActions';
import { showMessage } from '../reducers/msgReducer';
import { getConductorByRuta, getRutas, savetRuta } from '../services/adminServices';
import { useAppDispatch } from '../store/store';
import { propsConductor, propsRutas, tablePaginationRs } from '../utils/formsInitialsStates';




export const Rutas = () => {
  interface rutasConductorProps extends propsRutas {
    Conductor: Array<propsConductor>
  }
  const doneFetchActions = useDoneFetchActions();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({ count: 0 } as { count: number, rows: Array<{ [key: string]: any }> })
  const [isAddConducto, setIsAddConducto] = useState(false);
  const [dataRuta, setDataRuta] = useState({} as { on: string, data: rutasConductorProps | undefined })

  const rutaFormRef = useRef<{ handleClick: () => propsRutas; resetForm: () => void; }>();
  const conductorFormRef = useRef<{ handleClick: () => propsConductor; resetForm: () => void; }>();

  const handelPagination = useCallback((pagination: PaginatingAndSortingProps) => {
    function searchDataConductor(tipe: string, id: string) {
      doneFetchActions(getConductorByRuta(id)).then((resp: rutasConductorProps) => {
        setDataRuta({ on: tipe, data: resp });
      })
    }
    doneFetchActions(getRutas(pagination)).then((data: tablePaginationRs) => {
      data.rows = data.rows?.map(ob => ({
        ...ob,
        estado: <div className="w-100 text-center"><input type='checkbox' checked={ob.estado} disabled /></div>,
        actions: <>
          <button className='btn' onClick={() => searchDataConductor("edit", ob.id)}><span className="icon-create"></span></button>
          {/* <button className='btn' value={ob.id} data-bs-toggle="modal" data-bs-target="#RutaModelsForm" onClick={() => searchDataConductor("view", ob.id)}><span className="icon-remove_red_eye"></span></button> */}
        </>
      }))
      setData(ant => ({ ...ant, ...data }));
    });
  }, [doneFetchActions]);

  const onSaveModal = () => {
    const rutaValues = rutaFormRef.current?.handleClick();
    const conductorValues = conductorFormRef.current?.handleClick();
    if (rutaValues && (!isAddConducto || (isAddConducto && conductorValues))) {
      doneFetchActions(savetRuta({ ruta: rutaValues, conductor: conductorValues })).then(resp => {
        dispatch(showMessage({
          isShow: true,
          title: "ruta creada satisfactoriamente",
          type: 'success'
        }))
        handelPagination({} as PaginatingAndSortingProps);
        setDataRuta({ on: "", data: {} as rutasConductorProps });
        rutaFormRef.current?.resetForm();
        conductorFormRef.current?.resetForm();
      })
    }
  }

  const onCloseModal = () => {
    rutaFormRef.current?.resetForm();
    conductorFormRef.current?.resetForm();
    setDataRuta({ ...dataRuta, on: '' })
  }

  useEffect(() => {
    handelPagination({} as PaginatingAndSortingProps)
  }, [handelPagination])

  const titles = [
    { title: "Nombre", sort: "nombre" },
    { title: "Código", sort: "codigo" },
    { title: "Planta", name: "Plantum.nombre" },
    { title: "Estado", sort: "estado" },
    { title: "Acciones", name: "actions" }
  ]
  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <h4 className="align-self-end">Rutas</h4>
      </div>
      <div className="d-flex justify-content-end pb-4">
        <div className="align-self-end">
          <button onClick={() => { setDataRuta({ on: "add", data: undefined }); setIsAddConducto(false); }} type="button" className="btn btn-primary btn-rounded mb-4" >
            Crear Nueva
          </button>
        </div>
      </div>
      <div>
      </div>
      <Table dataTable={{ ...data, titles, limit: 100 }} onChange={handelPagination} />
      <Modals title={`Ruta`} show={!!dataRuta.on} onSave={onSaveModal} onClose={onCloseModal}>
        <RutaForm ref={rutaFormRef} data={dataRuta.data} on={dataRuta.on} />
        <>
          {dataRuta.on === "add" && <>
            <div className="d-flex justify-content-end">
              <button className='btn btn-primary' onClick={() => setIsAddConducto(c => !c)}>{isAddConducto ? 'Quitar' : 'Agregar'}  Conductor</button>
            </div>
            {isAddConducto && <>
              <div className="row">
                <h5 className="mb-3">Conductor</h5>
                <hr />
              </div>
              <ConductorForm ref={conductorFormRef} data={dataRuta.data?.Conductor[0]} on="addByRuta" />
            </>}
          </>
          }
        </>
      </Modals>
    </div>
  )
}
