import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";

interface Props {
  children?: React.ReactElement | React.ReactElement[];
  title: string;
  onClose?: () => void;
  onSave?: () => void;
  show: boolean
}

export const Modals = ({ children, title, onClose, onSave, show }: Props) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={onSave}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}