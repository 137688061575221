import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { fetchRS } from "../utils/fetchRS";


export const getPesos = (body: PaginatingAndSortingProps) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/peso/all`, {
    method: 'POST',
    objectBody: body
  });

export const getPesoById = (id: number) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/peso/${id}`);

export const savetPeso = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/peso/`, {
    method: 'PUT',
    objectBody
  })