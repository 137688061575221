import React, { useEffect, useState } from 'react'
import useForm from '../../../hooks/useForm';
import { useDoneFetchActions } from '../../../hooks/useDoneFetchActions';
import { getCuposByPlanta, getPlantas, getRutasByPlanta } from '../../../services/adminServices';
import Select from 'react-select'

interface props {
  runSearch: (values: PlantaFechaFilterProps) => void;
  initialValuesFilter?: Partial<PlantaFechaFilterProps>;
}

export interface PlantaFechaFilterProps {
  fechaFin: string,
  fechaIni: string,
  planta: number,
  ruta: number,
  cupo: number
}

export const PlantaFechas = ({ runSearch, initialValuesFilter = {} }: props) => {

  const doneFetchActions = useDoneFetchActions();

  const { handleInputChange, formValues, changeMulti } = useForm(initialValuesFilter as PlantaFechaFilterProps);
  const [rutas, setRutas] = useState([{ id: 0, nombre: '' }])
  const [plantas, setPlantas] = useState([{ id: 0, nombre: '' }])
  const [cupos, setCupos] = useState<Array<{ value: string; label: string; }>>()

  useEffect(() => {
    doneFetchActions(getPlantas()).then((resp: [{ id: 0, nombre: '' }]) => {
      setPlantas(resp);
    })
  }, [doneFetchActions])

  useEffect(() => {
    if (formValues.planta) {
      doneFetchActions(getRutasByPlanta(formValues.planta)).then((resp: [{ id: 0, nombre: '' }]) => {
        setRutas(resp)
      })
    } else {
      setRutas([])
    }
    changeMulti({ ruta: 0, cupo: 0 })
  }, [formValues.planta, doneFetchActions, changeMulti])

  useEffect(() => {
    doneFetchActions(getCuposByPlanta({ planta: formValues.planta, ruta: formValues.ruta })).then((resp: [{ id: string; codigo: string }]) => {
      setCupos(resp.map(ob => ({ value: String(ob.id), label: String(ob.codigo) })))
    })
    changeMulti({ cupo: 0 })
  }, [formValues.ruta, formValues.planta, doneFetchActions, changeMulti])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    runSearch(formValues);

  }
  return (
    <form onSubmit={handleSubmit} className="row g-3 align-items-center">
      <div className='row text-center'>
        <div className="col" style={{ minWidth: '12rem' }}>
          <div className="form-floating mb-3">
            <select
              className="form-select form-select-lg"
              id="planta"
              name="planta"
              value={formValues.planta}
              onChange={handleInputChange}
            >
              <option value={0} >Todas las Planta</option>
              {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="planta">plantas:</label>
          </div>
        </div>
        <div className="col" style={{ minWidth: '12rem' }}>
          <div className="form-floating mb-3">
            <select
              className="form-select form-select-lg"
              id="ruta"
              name="ruta"
              value={formValues.ruta}
              onChange={handleInputChange}
            >
              <option value={0} >Todas las Rutas</option>
              {rutas.map(p => <option key={`ruta_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="ruta">Rutas:</label>
          </div>
        </div>
        <div className="col" style={{ minWidth: '12rem' }}>
          <div className="form-floating mb-3">
            <Select
              className='form-select'
              placeholder='Seleccione el cupo'
              classNamePrefix='react-select-btsp'
              noOptionsMessage={() => 'Cupos no encontrados'}
              isClearable options={cupos}
              value={cupos?.find(cp => cp.value === String(formValues.cupo)) || { value: '' }}
              onChange={(a) => {
                handleInputChange({ target: { name: 'cupo', value: a?.value || '', type: '' } })
              }}
            />
            <label htmlFor="id_cupo_filtro">Cupo</label>
          </div>
        </div>
        <div className="col" style={{ minWidth: '12rem' }}>
          <div className="form-floating mb-3">
            <input
              type="date"
              id="fechaIni"
              name="fechaIni"
              value={formValues.fechaIni}
              onChange={handleInputChange}
              className="form-control form-control-lg"
            />
            <label htmlFor="fechaIni">Fecha inicial:</label>
          </div>
        </div>
        <div className="col" style={{ minWidth: '12rem' }}>
          <div className="form-floating mb-3">
            <input
              type="date"
              id="fechaFin"
              name="fechaFin"
              value={formValues.fechaFin}
              onChange={handleInputChange}
              className="form-control form-control-lg"
            />
            <label htmlFor="fechaFin">Fecha final:</label>
          </div>
        </div>
        <div className="col">
          <button type="submit" className="btn btn-lg btn-primary">
            <span className="icon-search"></span>
          </button>
        </div>
      </div>
    </form>
  )
}
