import { fetchRS } from "../utils/fetchRS"

export interface PropsUserLogin {
  username: string;
  password: string
}

export const signIn = (user: PropsUserLogin) => 
  fetchRS(`${process.env.REACT_APP_API_URL}/login/signin`, { method: 'POST', objectBody: user });

export const isAuntenticate = () => 
  fetchRS(`${process.env.REACT_APP_API_URL}/login/protected`);