import { useCallback, useEffect, useRef, useState } from "react";
import useForm from "../hooks/useForm";
import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { initialPlantas, propsPlantas } from "../utils/formsInitialsStates";
import { getPlantas } from "../services/adminServices";
import { payDriversByDates } from "../services/reportesServices";
import { Table } from "../components/table-pagination-sorting/components/Table";

export const PagoConductores = () => {
  const { handleInputChange, formValues } = useForm({
    fechaIni: '',
    fechaFin: '',
    planta: 0
  });
  const pages = useRef({} as PaginatingAndSortingProps)
  const [data, setData] = useState({ count: 0 } as { count: number, rows: Array<{ [key: string]: any }> });

  const doneFetchActions = useDoneFetchActions();

  const [plantas, setPlantas] = useState([initialPlantas])

  const updateTabla = useCallback((filtro: any) => {
    doneFetchActions(payDriversByDates(filtro))
      .then(data => {
        setData(ant => ({ ...ant, rows: data, count: data.length }));
      });
  }, [doneFetchActions])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateTabla({ ...formValues, ...pages.current });
  };

  const handleChangeTable = (pageAction: PaginatingAndSortingProps) => {
    pages.current = pageAction;
    updateTabla({ ...formValues, ...pageAction });
  };

  useEffect(() => {
    doneFetchActions(getPlantas()).then((resp: [propsPlantas]) => {
      setPlantas(resp);
    })
  }, [doneFetchActions])

  function downCSV() {
    doneFetchActions(payDriversByDates({ ...pages.current, ...formValues }, 'csv'))
  }

  const titles = [
    { title: "Cédula", name: "cedula" },
    { title: "Nombre", name: ["primerNombre", "primerApellido"] },
    { title: "Ruta", name: "nombreRuta" },
    { title: "Litros Total", name: "totalLitros" },
    { title: "Tipo de Pago", name: "tipoPago" },
    { title: "Precio", name: "pago" },
    { title: "Total del Pago", name: "totalPago" },
  ]

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div>
            <h2 className="text-center mb-4">Reporte de litros por meses</h2>
            <form onSubmit={handleSubmit} className="row g-3 align-items-center">
              <div className="col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    id="fechaIni"
                    name="fechaIni"
                    value={formValues.fechaIni}
                    onChange={handleInputChange}
                    className="form-control form-control-lg"
                  />
                  <label htmlFor="fechaIni">Fecha inicial:</label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    id="fechaFin"
                    name="fechaFin"
                    value={formValues.fechaFin}
                    onChange={handleInputChange}
                    className="form-control form-control-lg"
                  />
                  <label htmlFor="fechaFin">Fecha Final:</label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-floating mb-3">
                  <select
                    className="form-select form-select-lg"
                    id="planta"
                    name="planta"
                    value={formValues.planta}
                    onChange={handleInputChange}
                  >
                    <option value={0} disabled>Seleccione una Planta</option>
                    {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
                  </select>
                  <label htmlFor="planta">Plantas:</label>
                </div>
              </div>
              <div className="col-lg-3 mt-0">
                <button type="submit" className="btn btn-lg btn-primary w-100 h-100">
                  Filtrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Table dataTable={{ ...data, titles }} onChange={handleChangeTable} />
      <div className='d-flex justify-content-end'>
        <button onClick={downCSV} className='btn btn-secondary'>csv</button>
      </div>
    </div>
  )
}
