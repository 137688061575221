import { useEffect, useState } from "react";
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { getHomeInfo } from "../services/reportesServices";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


interface DataProps {
  litrosSemanal: ChartData<"line", any, string>;
  proveedoresSemanal: ChartData<"line", any, string>;
}

export const Home = () => {
  const [data, setdata] = useState<DataProps>({ litrosSemanal: { datasets: [], labels: [] }, proveedoresSemanal: { datasets: [], labels: [] } })
  const doneFetchActions = useDoneFetchActions();
  useEffect(() => {
    doneFetchActions(getHomeInfo()).then((resp: { litrosSemanal: Array<{ [key: string]: string | number }>; proveedoresSemanal: Array<{ [key: string]: string | number }> }) => {
      const { litrosSemanal, proveedoresSemanal } = resp
      console.log(litrosSemanal, proveedoresSemanal);
      const getDataChart = (datos: Array<{ [key: string]: string | number }>) => {
        let datasets: any = [];
        let labels: any = []
        datos.forEach((ob, i) => {
          const { nombre, ...dias } = ob;
          if (i < 1) labels = Object.keys(dias);
          datasets.push({
            label: nombre,
            data: Object.values(dias),
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(53, 162, 235, 0.5)'][i],
            borderColor: ['rgba(255, 99, 132, 0.5)', 'rgba(53, 162, 235, 0.5)'][i],
          })
        })
        return { datasets, labels }
      }

      setdata({ litrosSemanal: getDataChart(litrosSemanal), proveedoresSemanal: getDataChart(proveedoresSemanal) })
    })
  }, [doneFetchActions])
  const getOptions = (text: string) => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text,
      },
    },
  })
  return (
    <div className="container">
      <div className="row g-2">
        <div className="col-md-12 col-lg-6 p-4">
          <Line
            options={getOptions('Litros Diarios Por Planta')}
            data={data.litrosSemanal}
          />
        </div>
        <div className="col-md-12 col-lg-6 p-4">
          <Line
            options={getOptions("Total Diario de Provvedores por Planta")}
            data={data.proveedoresSemanal}
          />
        </div>
      </div>
    </div>
  )
}

