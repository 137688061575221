import { Modals } from "../components/Modals";
import { Table } from "../components/table-pagination-sorting/components/Table";
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { useAppDispatch } from "../store/store";
import { useState, useRef, useEffect, useCallback } from 'react';
import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { initialProveedores, propsProveedores, tablePaginationRs } from '../utils/formsInitialsStates';
import { showMessage } from "../reducers/msgReducer";
import { ProveedorForm } from "../components/forms-modals/ProveedorForm";
import { getProveedor, getProveedores, savetProveedor } from "../services/adminServices";

export const Proveedores = () => {

  const doneFetchActions = useDoneFetchActions();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({ count: 0 } as { count: number, rows: Array<{ [key: string]: any }> })
  const [datosProveedor, setDatosProveedor] = useState({} as { on: string, data: propsProveedores | undefined })

  const proveedorFormRef = useRef<{ handleClick: () => propsProveedores; resetForm: () => void; }>();
  const handelPagination = useCallback((pagination: PaginatingAndSortingProps) => {
    function searchDataProveedor(tipe: string, id: number) {
      doneFetchActions(getProveedor(id)).then((resp: propsProveedores) => {
        setDatosProveedor({ on: tipe, data: resp });
      })
    }
    doneFetchActions(getProveedores(pagination)).then((data: tablePaginationRs) => {
      data.rows = data.rows?.map(ob => ({
        ...ob,
        estado: <div className="w-100 text-center"><input className="form-check-input" type='checkbox' checked={ob.estado} disabled /></div>,
        actions: <>
          <button className='btn' onClick={() => searchDataProveedor("edit", ob.id)}><span className="icon-create"></span></button>
          {/* <button className='btn' value={ob.id} data-bs-toggle="modal" data-bs-target="#ProveedorModelsForm" onClick={() => searchDataProveedor("view", ob.id)}><span className="icon-remove_red_eye"></span></button> */}
        </>
      }))
      setData(ant => ({ ...ant, ...data }));
    })
  }, [doneFetchActions]);

  const onSaveModal = () => {
    const proveedorValues = proveedorFormRef.current?.handleClick();
    if (proveedorValues) {
      doneFetchActions(savetProveedor(proveedorValues)).then(resp => {
        dispatch(showMessage({
          isShow: true,
          title: "Proveedor Guardado satisfactoriamente",
          type: 'success'
        }))
        handelPagination({} as PaginatingAndSortingProps);
        setDatosProveedor({ on: "", data: initialProveedores });
        proveedorFormRef.current?.resetForm();
      })
    }
  }
  useEffect(() => {
    handelPagination({} as PaginatingAndSortingProps)
  }, [handelPagination])

  const onCloseModal = () => {
    proveedorFormRef.current?.resetForm();
    setDatosProveedor({ ...datosProveedor, on: '' })
  }

  const titles = [
    { title: "Cédula", sort: "cedula" },
    { title: "Nombre", name: ['primer_nombre', 'primer_apellido'], sort: 'primer_nombre' },
    { title: "Ruta", name: "Cupo.Rutum.nombre" },
    { title: "Cupo", name: "Cupo.codigo" },
    { title: "DV", sort: "dv" },
    { title: "Estado", sort: "estado" },
    { title: "Acciones", name: "actions" }
  ]
  /**
   tipo_pago:'PL',
  pago_litros: 0,
  pago_fijo: 0,
   */
  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <h4 className="align-self-end">Proveedores</h4>
      </div>
      <div className="d-flex justify-content-end pb-4">
        <div className="align-self-end">
          <button onClick={() => { setDatosProveedor({ on: "add", data: undefined }); }} type="button" className="btn btn-primary btn-rounded mb-4">
            Nuevo Proveedor
          </button>
        </div>
      </div>
      <div>
      </div>
      <Table dataTable={{ ...data, titles, limit: 100 }} onChange={handelPagination} />
      <Modals title="Proveedor" show={!!datosProveedor.on} onSave={onSaveModal} onClose={onCloseModal}>
        <ProveedorForm ref={proveedorFormRef} data={datosProveedor.data} on={datosProveedor.on} />
      </Modals>
    </div>
  )
}
