import { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import useForm from "../../hooks/useForm";
import { initialConductor, initialPlantas, propsConductor, propsPlantas, propsRutas } from '../../utils/formsInitialsStates';
import { getPlantas, getRutasByPlanta } from '../../services/adminServices';
import { useDoneFetchActions } from '../../hooks/useDoneFetchActions';
import { getBanco } from '../../services/catalogosServices';

interface Props {
  handleSubmit?: (values: propsConductor) => void
  data?: propsConductor,
  on?: string
}
export const ConductorForm = forwardRef(({ handleSubmit, data, on }: Props, ref) => {
  const doneFetchActions = useDoneFetchActions();
  const { handleInputChange, formValues, resetForm, changeMulti, validateForm } = useForm<propsConductor>(initialConductor);
  const form = useRef<HTMLFormElement>(null);
  const [plantas, setPlantas] = useState([initialPlantas])
  const [rutas, setRutas] = useState([{ id: 0, nombre: '' }])
  const [bancos, setBancos] = useState([{ id: 0, nombre: '' }])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit && handleSubmit(formValues)
  }
  useEffect(() => {
    const plantasBancos = Promise.all([getPlantas(), getBanco({ offset: 0, limit: 100 })])
    doneFetchActions(plantasBancos).then((resp: [[propsPlantas], { rows: [{ id: number; nombre: string }] }]) => {
      setPlantas(resp[0]);
      setBancos(resp[1]?.rows)
    })
  }, [doneFetchActions])

  useEffect(() => {
    if (formValues.id_planta) {
      doneFetchActions(getRutasByPlanta(formValues.id_planta)).then((resp: [propsRutas]) => {
        setRutas(resp)
      })
    }
  }, [formValues.id_planta, doneFetchActions])

  useImperativeHandle(ref, () => ({
    handleClick: () => {
      form.current?.requestSubmit();
      if (form.current && validateForm(form.current.elements)) {
        return formValues;
      }
    },
    resetForm: () => resetForm()
  }));
  useEffect(() => {
    if (data) changeMulti(data);
  }, [data, changeMulti])

  return (
    <form className="p-3" id='formBancos' ref={form} autoComplete="off" onSubmit={submitForm}>
      {on !== "addByRuta" && < div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_planta" name="id_planta" value={formValues.id_planta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Planta</option>
              {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_planta">Planta</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_ruta" name="id_ruta" value={formValues.id_ruta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Ruta</option>
              {rutas.map(p => <option key={`ruta_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_ruta">Ruta</label>
          </div>
        </div>
      </div>}
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="cedula" name="cedula" value={formValues.cedula} onChange={handleInputChange}
              placeholder="-" data-type='alphanumeric' data-exact-length={10} data-trim="submit" required />
            <label htmlFor="cedula">Cédula</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="primer_nombre" name="primer_nombre" value={formValues.primer_nombre} onChange={handleInputChange}
              placeholder="-" data-type='string' data-min={2} data-capitalize="change" data-trim="submit" required />
            <label htmlFor="primer_nombre">Primer nombre</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="segundo_nombre" name="segundo_nombre" value={formValues.segundo_nombre} onChange={handleInputChange}
              placeholder="-" data-type='string' data-min={2} data-capitalize="change" data-trim="submit" />
            <label htmlFor="segundo_nombre">Segundo nombre</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="primer_apellido" name="primer_apellido" value={formValues.primer_apellido} onChange={handleInputChange}
              placeholder="-" data-type='string' data-min={2} data-capitalize="change" data-trim="submit" required />
            <label htmlFor="primer_apellido">Primer apellido</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="segundo_apellido" name="segundo_apellido" value={formValues.segundo_apellido} onChange={handleInputChange}
              placeholder="-" data-type='string' data-min={2} data-capitalize="change" data-trim="submit" />
            <label htmlFor="segundo_apellido">Segundo apellido</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="dv" name="dv" value={formValues.dv} onChange={handleInputChange}
              placeholder="-" data-trim="submit" />
            <label htmlFor="dv">Dígito Verificador</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="tipo_pago" name="tipo_pago" value={formValues.tipo_pago} onChange={handleInputChange} required>
              <option value='' disabled>Seleccione una tipo de pago</option>
              <option value='PL'>Pago por litros</option>
              <option value='PF'>Pago Fijo</option>
            </select>
            <label htmlFor="tipo_pago">Tipo de pago</label>
          </div>
        </div>
        {formValues.tipo_pago === 'PL' && <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="pago_litros" name="pago_litros" value={formValues.pago_litros} onChange={handleInputChange}
              placeholder="-" data-type='number' data-min={0} required />
            <label htmlFor="pago_litros">Precio por Litro</label>
          </div>
        </div>}
        {formValues.tipo_pago === 'PF' && <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="pago_fijo" name="pago_fijo" value={formValues.pago_fijo} onChange={handleInputChange}
              placeholder="-" data-type='number' data-min={0} required />
            <label htmlFor="pago_fijo">Pago fijo</label>
          </div>
        </div>}
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="forma_pago" name="forma_pago" value={formValues.forma_pago} onChange={handleInputChange} required>
              <option value='' disabled>Seleccione una forma de pago</option>
              <option value='ACH'>ACH</option>
              <option value='MNY'>Efectivo</option>
              <option value='CHQ'>Cheque</option>
            </select>
            <label htmlFor="segundo_apellido">Forma de pago</label>
          </div>
        </div>
        {formValues.forma_pago === 'ACH' && <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_banco" name="id_banco" value={formValues.id_banco} onChange={handleInputChange} >
              <option value={0} disabled>Seleccione una Banco</option>
              {bancos.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_banco">Banco</label>
          </div>
        </div>}
      </div>
      {
        formValues.forma_pago === 'ACH' && <div className="row">
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="nombre_cuenta" name="nombre_cuenta" value={formValues.nombre_cuenta} onChange={handleInputChange}
                placeholder="-" data-type='alphanumeric' data-exact-length={10} data-trim="submit" />
              <label htmlFor="nombre_cuenta">Numero de Cuenta</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select className="form-select" id="tipo_cuenta" name="tipo_cuenta" value={formValues.tipo_cuenta} onChange={handleInputChange} >
                <option value='' disabled>Seleccione una tipo de cuenta</option>
                <option value='AHO'>Ahorro</option>
                <option value='CRT'>Corriente</option>
              </select>
              <label htmlFor="tipo_cuenta">Tipo de Cuenta</label>
            </div>
          </div>
        </div>
      }
    </form >
  )
})
