import React from 'react'
import './spiner.css'
interface Props {
    styleSp?: React.CSSProperties | undefined;
    styleCt?: React.CSSProperties | undefined
}
export const Spiner = ({ styleSp, styleCt }: Props) => {
    return (
        <div className="conten_spiner" style={styleCt}>
            <div style={styleSp} className="spiner icon-spinner2"></div>
        </div>
    )
}
