import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDoneFetchActions } from "../../hooks/useDoneFetchActions";
import useForm from "../../hooks/useForm";
import { initialCupo, propsCupo, initialPlantas, propsPlantas, propsRutas } from '../../utils/formsInitialsStates';
import { getPlantas, getRutasByPlanta } from '../../services/adminServices';

interface Props {
  handleSubmit?: (values: propsCupo) => void;
  data?: propsCupo,
  on?: string
}

export const CupoForm = forwardRef(({ handleSubmit, data, on }: Props, ref) => {
  const doneFetchActions = useDoneFetchActions();
  const { handleInputChange, formValues, resetForm, changeMulti, validateForm } = useForm<propsCupo>(initialCupo);
  const form = useRef<HTMLFormElement>(null);
  const [plantas, setPlantas] = useState([initialPlantas])
  const [rutas, setRutas] = useState([{ id: 0, nombre: '' }])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit && handleSubmit(formValues)
  }
  useEffect(() => {
    doneFetchActions(getPlantas()).then((resp: [propsPlantas]) => {
      setPlantas(resp);
    })
  }, [doneFetchActions])

  useEffect(() => {
    if (formValues.id_planta) {
      doneFetchActions(getRutasByPlanta(formValues.id_planta)).then((resp: [propsRutas]) => {
        setRutas(resp)
      })
    }
  }, [formValues.id_planta, doneFetchActions])

  useImperativeHandle(ref, () => ({
    handleClick: () => {
      form.current?.requestSubmit();
      if (form.current && validateForm(form.current.elements)) {
        return formValues;
      }
    },
    resetForm: () => resetForm()
  }));
  useEffect(() => {
    if (data) changeMulti(data);
  }, [data, changeMulti])
  return (
    <form className="p-3" ref={form} autoComplete="off" onSubmit={submitForm}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_planta" name="id_planta" value={formValues.id_planta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Planta</option>
              {plantas.map(p => <option key={`plata_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_planta">Planta</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="id_ruta" name="id_ruta" value={formValues.id_ruta} onChange={handleInputChange} required>
              <option value={0} disabled>Seleccione una Ruta</option>
              {rutas.map(p => <option key={`ruta_${p.id}`} value={p.id}>{p.nombre}</option>)}
            </select>
            <label htmlFor="id_ruta">Ruta</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="codigo" name="codigo" value={formValues.codigo} onChange={handleInputChange}
              placeholder="-" data-type='alphanumeric' data-uppercase="change" data-trim="change" required />
            <label htmlFor="codigo">Código</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <select className="form-select" id="estado" name="estado" value={Number(formValues.estado)} onChange={handleInputChange} required>
              <option value="" disabled>Seleccione un estado</option>
              <option value={1}>Activo</option>
              <option value={0}>Inactivo</option>
            </select>
            <label htmlFor="estado">Estado</label>
          </div>
        </div>
      </div>
    </form>

  )
})
