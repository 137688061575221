import { AnimatePresence, motion } from 'framer-motion';
import { Spiner } from './Spiner'
interface Props { 
    isShow: boolean;
    fontSize?: string;
    color?: string;
}
export const SpinerAllContainer = ({ isShow, fontSize = '40px', color = 'cyan' }: Props) => {
    const defaultState = {
        opacity: 0,
        scale: 1
    };
    const defaultStateExit = {
        opacity: 0,
        scale: 0.2
    };
    return (
        <AnimatePresence>

            {isShow &&
                <motion.div
                    initial={defaultState}
                    exit={defaultStateExit}
                    animate={{
                        opacity: 1,
                        scale: 1,
                    }}
                    transition={{
                        duration:.5
                    }}
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 9999999, backgroundColor: '#00000065' }}
                >
                    <Spiner styleSp={{ fontSize: fontSize, color: color }} />
                </motion.div>
            }
        </AnimatePresence>
    )
}
