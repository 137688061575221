import { MsgProps } from "./interfaces";
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isShow: false,
    body: '',
    title: '',
    type: "info",
} as MsgProps

const msgReducer = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        showMessage: (state, action) => {
            state.isShow = true;
            state.body = action.payload.body;
            state.title = action.payload.title || "";
            state.type = action.payload.type;

        },
        hideMessage: (state) => {
            state.isShow = false;
        }
    }
})

export default msgReducer.reducer;
export const { hideMessage, showMessage } = msgReducer.actions;
