import { useState, useEffect } from 'react';
import { Table } from '../components/table-pagination-sorting/components/Table';
import { PaginatingAndSortingProps } from '../components/table-pagination-sorting/interfaces/interfaces';
import { useDoneFetchActions } from "../hooks/useDoneFetchActions";
import { reporteMida } from "../services/reportesServices";
import { PlantaFechas, PlantaFechaFilterProps } from '../components/others/Filters/PlantaFechas';

export const Reportes = () => {

  const titles = [
    {
      title: "Nombre",
      sort: "Proveedor.nombre"
    },
    {
      title: "Cédula",
      sort: "Proveedor.cedula"
    },
    {
      title: "Cupo",
      sort: "Cupo.codigo",
      name: "Cupo.codigo"
    },
    {
      title: "Litros",
      sort: "cantidad",
      name: "cantidad_total"
    },
    {
      title: "Precio",
      sort: "Proveedor.precio_litro"
    },
    {
      title: "Total Pagado",
      name: "pago_planta"
    },
    {
      title: "Precio Subsidio",
      name: "precio_subsidio"
    },
    {
      title: "Monto Subsidio",
      name: "pago_subsidio"
    }
  ]
  const doneFetchActions = useDoneFetchActions();
  const [data, setData] = useState({ count: 0 } as { count: number; rows: Array<{ [key: string]: any }>; info: any })
  const [filterData, setDataFilter] = useState({ fechaIni: new Intl.DateTimeFormat("fr-CA").format(Date.now()) } as filtersProps);
  interface filtersProps extends PaginatingAndSortingProps, PlantaFechaFilterProps { }
  useEffect(() => {
    doneFetchActions(reporteMida(filterData))
      .then(data => {
        setData(ant => ({ ...ant, ...data }));
      });
  }, [filterData, doneFetchActions])

  const handleChangeTable = (pageAction: PaginatingAndSortingProps) => {
    setDataFilter((dt) => ({ ...dt, ...pageAction }));
  }
  const handleSubmit = (dataFilter: PlantaFechaFilterProps) => {
    setDataFilter((dt) => ({ ...dt, ...dataFilter }));
  }
  function downCSV() {
    doneFetchActions(reporteMida(filterData, 'csv'))
  }


  return (
    <div>
      <div className="row justify-content-center">

        <div>
          <h2 className="text-center mb-4">Reporte de rutas por fechas</h2>
          <PlantaFechas runSearch={handleSubmit} />
        </div>
      </div>
      <h5 className="text-end bg-secondary rounded-3 px-4 py-1 text-white">Peso Total: {data.info?.pesajeTotal || '0.0'}</h5>
      <Table dataTable={{ ...data, titles }} onChange={handleChangeTable} />
      <div className='d-flex justify-content-end'>
        <button onClick={downCSV} className='btn btn-secondary'>csv</button>
      </div>
    </div>

  )
}
