import { PaginatingAndSortingProps } from "../components/table-pagination-sorting/interfaces/interfaces";
import { fetchRS } from "../utils/fetchRS";


export const getRutas = (body: PaginatingAndSortingProps) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/ruta/get-rutas`, {
    method: 'POST',
    objectBody: body
  });
export const getConductorByRuta = (ruta: string) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/ruta/get-ruta/${ruta}`);
export const getPlantas = () =>
  fetchRS(`${process.env.REACT_APP_API_URL}/ruta/plantas`)
export const savetRuta = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/ruta/save-ruta`, {
    method: 'POST',
    objectBody
  })
export const getRutasByPlanta = (planta: number) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/ruta/rutas-by-plantas/${planta}`);





export const getConductores = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/conductor/all`, {
    method: 'POST',
    objectBody
  });
export const getConductor = (id: number) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/conductor/${id}`);
export const savetConductor = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/conductor/`, {
    method: 'PUT',
    objectBody
  })

export const cuposDisbonibles = (id_cupo: number) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/cupo/available?actual=${id_cupo}`);

export const getProveedores = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/proveedor/all`, {
    method: 'POST',
    objectBody
  });
export const getProveedor = (id: number) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/proveedor/${id}`);
export const savetProveedor = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/proveedor/`, {
    method: 'PUT',
    objectBody
  })

export const getCupos = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/cupo/all`, {
    method: 'POST',
    objectBody
  });
export const getCuposByPlanta = (filter: { planta?: number, ruta?: number }) => fetchRS(`${process.env.REACT_APP_API_URL}/cupo/filters`, undefined, filter);
export const getCupo = (id: number) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/cupo/${id}`);
export const saveCupo = (objectBody: any) =>
  fetchRS(`${process.env.REACT_APP_API_URL}/cupo/`, {
    method: 'PUT',
    objectBody
  })

